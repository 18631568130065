import styled, { css } from 'styled-components'
import {
  mq,
  colors,
  spacing,
  typography,
} from '../../../styles/utilities/variables'
import StyledContent from '../../Content/style'
import StyledText from '../../Text/style'
import StyledHeading from '../../Heading/style'
import StyledForm from '../../Form/style'
import StyledButton from '../../Button/style'
import StyledWrapper from '../../Wrapper/style'

export const StyledNumberInput = styled.input`
  appearance: none;
  border: none;
  padding: 0.8rem;
  border-radius: 4px;
  max-width: 12.8rem;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;

  &:focus-visible {
    outline: none;
  }
`

export const StyledResult = styled.span`
  font-weight: 600;
  font-size: 2rem;
  padding: 1rem;
  border-radius: 4px;
  background-color: ${colors.forestGreen};
  color: ${colors.white};
  text-align: right;
`

export const StyledLabel = styled.label`
  color: ${colors.lightYellow};
  font-weight: 600;
  font-size: 2rem;
`

export const StyledDescription = styled.p`
  font-size: ${typography.sizes.xSmall};
  margin: unset;
  font-weight: 500;
  color: ${colors.lightYellow};
  line-height: 1.3;
`


export const StyledCalculator = styled.div`
  z-index: 2;
  grid-row: 1;
  grid-column: 4 / 10;
  display: flex;
  flex-direction: column;
  align-self: center;

  ${StyledWrapper} {
    width: 100%;
  }

  /* @media ${mq.desktopLarge} {
    grid-column: 2/7;
  }

  @media ${mq.tablet} {
 //   grid-column: 2/14;
    grid-row: 1/3;
    align-self: flex-start;

  }

  @media ${mq.mobile} {
    align-self: flex-start;
  }

  @media ${mq.tablet} {
    margin-top: ${spacing.xxxLarge};
  } */
 
  ${StyledContent} {
    /* max-width: 55.6rem; */
    
    ${StyledHeading.h3} {
      margin-bottom: ${spacing.base};
      text-align: center;
    }

    p {
      text-align: center;
      max-width: unset;
    }

    @media ${mq.tablet} {
      max-width: unset;
    }

    @media ${mq.mobile} { 
      ${StyledHeading.h3} { 
        font-size: ${typography.sizes.medium};
     }

      ${StyledText} { 
        font-size: ${typography.sizes.xSmall};
      }
    
    }

  }


  ${StyledForm.Input} {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid ${colors.lightYellow};
    max-width: unset;
    font-size: ${typography.sizes.xxLarge};
    font-family: ${typography.MatrisePro};
    color: ${colors.brightYellow};
    padding: ${spacing.base} 0;
    border-radius: 0px;
    ${props =>
      props.themeColor === 'blueTheme' &&
      css`
        border-bottom: 1px solid ${colors.pink};
        color: ${colors.pink};
      `}
    @media ${mq.mobile} { 
     font-size: ${typography.sizes.large};
     padding: ${spacing.xSmall} 0;
     }
    
  }
  ${StyledForm.Label} {
    color: ${colors.lightYellow};
    padding: ${spacing.small} 0 ;
    ${props =>
      props.themeColor === 'blueTheme' &&
      css`
        color: ${colors.white};
      `}
  }

  ${StyledButton} {
    width: 16rem;
    position: absolute;
    right: 0;
    z-index: 2;

    @media ${mq.mobile} { 
         width: fit-content;
         padding: ${spacing.small} ${spacing.base};
     }
  }
  ${StyledWrapper} {
    position: relative;
  //  margin-top: ${spacing.large};
    padding: 0;
    ${StyledForm.Input} {
      padding-right: 18rem;
      @media ${mq.mobile} { 
        padding-right: 12rem;
     }
    }

 
  }
`

export const StyledSliderNative = styled.div`
  width: 100%;
  input {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    border-radius: 9999px;
    height: 12px;
    background: white;
    &::-webkit-slider-thumb {
      appearance: none;
      position: relative;
      background-color: ${colors.lightYellow};
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
      width: 24px;
      aspect-ratio: 1;
      border-radius: 50%;
      z-index: 2;
    }
  }
`
