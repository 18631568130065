import React, { useState, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Form } from '../../components/Form'
import { Button } from '../../components/Button'
import { Loader } from '../../components/Loader'
import { sendNewsletterForm } from '../../helpers/api'
import { REQUIRED_TEXT, CUSTOMER_FIELDS } from '../../helpers/form'
import { Divider } from '../../components/Divider'
import { COLORS, SPACING } from '../../helpers/constants'
import { useFormSettings } from '../../hooks/useFormSettings'
import { parseContent } from '../../helpers/texts'
import { Content } from '../../components/Content'
import { Heading } from '../../components/Heading'
import { Icon } from '../../components/Icon'
import { AlignWrapper } from '../../components/AlignWrapper'
import { Error } from '../Messages'
import { catchError, hash } from '../../helpers/utilities'
import { getString } from '../../helpers/lang'
import { doTrackInterestForm, pushDataLayer } from '../../helpers/tracking'
import { Preamble } from '../../components/Text/Preamble'

const Newsletter = () => {
  const formSettings = useFormSettings()
  const { formConsentText } = formSettings

  /**
   * Setup state
   */
  const { handleSubmit, register, errors, formState } = useForm()
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)
  const [error, setError] = useState(false)

  /**
   * Handle submit
   * @param {*} data
   */
  const onSubmit = data => {
    setLoading(true)
    sendNewsletterForm(data)
      .then(response => {
        setSent(true)
        pushDataLayer({
          event: 'formSubmission',
          formType: 'Newsletter Signup',
          customer_email: data?.email,
          customer_email_sha256: hash(data?.email)
        })
      })
      .catch(error => {
        setError(true)
        catchError(error, data)
      })
    // Tracking
    // const { type, brand } = data
    // doTrackInterestForm(brand, type)
  }
  const isSubmitted = formState.isSubmitted

  /**
   * Error view
   */
  if (error) {
    return <Error />
  }

  /**
   * Success view
   */
  if (sent) {
    return (
      <Content align={'center'}>
        <AlignWrapper align={'center'}>
          <Icon color={COLORS.GREEN} size={90} icon={'check-circle'} />
        </AlignWrapper>
        <Divider />
        <Heading align={'center'} type={'h3'}>
          {getString('FORM_NEWSLETTER_SUCCESS_HEADER')}
        </Heading>
        <Preamble center>{getString('FORM_NEWSLETTER_SUCCESS_TEXT')}</Preamble>
      </Content>
    )
  }

  // Get email field
  const field = CUSTOMER_FIELDS.filter(f => f.name === 'email')[0]

  return (
    <Form onSubmit={handleSubmit(onSubmit)} center>
      <Form.Group>
        <Form.Input
          type={'email'}
          valid={isSubmitted && !errors['email']}
          inValid={errors['email']}
          label={getString('FORM_EMAIL_LABEL')}
          name={'email'}
          innerRef={register({
            required: REQUIRED_TEXT,
            pattern: field.pattern,
          })}
        ></Form.Input>
        {errors['email'] && <Form.Error>{errors['email'].message}</Form.Error>}
      </Form.Group>

      <Form.Group>
        <Form.Checkbox
          label={parseContent(formConsentText)}
          name={'consent'}
          innerRef={register({
            required: REQUIRED_TEXT,
          })}
        ></Form.Checkbox>
        {errors.consent && <Form.Error>{errors.consent.message}</Form.Error>}
      </Form.Group>

      <Form.Group>
        {loading && <Loader align={'left'} />}
        {!loading && <Button>{getString('FORM_SUBMIT_LABEL')}</Button>}
      </Form.Group>
    </Form>
  )
}

export default Newsletter
