import React from 'react'
import PropTypes from 'prop-types'
import { ImageCard } from '../ImageCard'
import StyledInteractiveHero from './style'
import { getUrl } from '../../helpers/url'

const InteractiveHero = ({
  children,
  mediaType,
  video,
  videoMobile,
  image,
  theme,
  overlay,
  imageEffects,
  contentAlignment = 'center',
  buttonCaption,
  buttonHref,
  secondButtonCaption,
  secondButtonHref,
  heroHeight = '64.5rem',
  background = true,
}) => {
  if (!image) {
    background = false
    heroHeight = null
  }

  return (
    <StyledInteractiveHero background={background} overlay={overlay}>
      <ImageCard
        height={heroHeight}
        children={children}
        mediaType={mediaType}
        theme={theme}
        video={video}
        videoMobile={videoMobile}
        image={image}
        imageEffects={imageEffects}
        // contentAlignment={contentAlignment}
        buttonCaption={buttonCaption}
        buttonHref={getUrl(buttonHref)}
        secondButtonCaption={secondButtonCaption}
        secondButtonHref={getUrl(secondButtonHref)}
      />
    </StyledInteractiveHero>
  )
}

InteractiveHero.propTypes = {
  children: PropTypes.any,
  image: PropTypes.object,
  video: PropTypes.object,
  mediaType: PropTypes.string,
  theme: PropTypes.string,
  imageEffects: PropTypes.shape({
    useBlur: PropTypes.bool,
    color: PropTypes.string,
    effectDirection: PropTypes.string,
    darken: PropTypes.string,
    makeBlackAndWhite: PropTypes.bool,
  }),
  contentWidth: PropTypes.string,
  contentAlignment: PropTypes.string,
  buttonCaption: PropTypes.string,
  buttonHref: PropTypes.string,
  secondButtonCaption: PropTypes.string,
  secondButtonHref: PropTypes.string,
  background: PropTypes.bool,
}
export { InteractiveHero }
