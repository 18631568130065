import React from 'react'
import {
  StyledNumber,
  StyledSlimStep,
  StyledSlimStepContent,
  StyledSlimSteps,
} from './style'
import { Heading } from '../Heading'
import { Button } from '../Button'

export const SlimSteps = props => {
  const { steps } = props

  /**
   * @description replaces '**' with a corresponding <b> tag
   * @param {string} string
   * @returns {string}
   */
  const boldify = string => {
    const containsMarkdown = () =>
      string.indexOf('**') > -1 && string.indexOf('**', 1)

    for (let i = 0; ; i++) {
      if (!containsMarkdown()) break

      const first = i % 2 === 0

      if (first) {
        // replace with starting tag
        string = string.replace('**', '<b>')
      } else {
        // replace with closing tag
        string = string.replace('**', '</b>')
      }
    }

    return string
  }

  return (
    <StyledSlimSteps>
      {steps
        ? steps.map((step, index) => (
            <StyledSlimStep>
              <StyledNumber>{index + 1}</StyledNumber>
              <StyledSlimStepContent>
                {step.title ? (
                  <Heading type='h3' color='lightGreen_02'>
                    {step.title}
                  </Heading>
                ) : null}
                {step.text ? (
                  <p dangerouslySetInnerHTML={{ __html: boldify(step.text) }} />
                ) : null}
                {step.link ? (
                  <Button to={step.link.url}> {step.link.title}</Button>
                ) : null}
              </StyledSlimStepContent>
            </StyledSlimStep>
          ))
        : null}
    </StyledSlimSteps>
  )
}
