import styled, { css } from 'styled-components'
import { darken } from 'polished'
import {
  colors,
  typography,
  mq,
  spacing,
} from '../../styles/utilities/variables'
import StyledHeading from '../Heading/style'
import StyledButton from '../Button/style'
import StyledText from '../Text/style'

export const StyledHeroCircularContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
  margin-top: -6.4rem;
`

export const StyledHeroCircular = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
  margin-bottom: ${spacing.xxxLarge};
  min-height: 100rem;

  position: relative;
  @media ${mq.mobile} {
    display: flex;
    flex-direction: column;
    min-height: unset;
    margin-bottom: 0;
    ${props =>
      props.theme === 'pink' &&
      css`
        background-color: ${colors.darkPink};
      `}
    ${props =>
      props.theme === 'green' &&
      css`
        background-color: ${colors.darkGreen};
      `}
    ${props =>
      props.theme === 'standard' &&
      css`
        background-color: ${colors.primary};
      `}

      ${props =>
        props.theme === 'blue' &&
        css`
          background-color: ${colors.darkBlue};
        `}
  }

  @media (min-width: 1220px) {
    // this hax because it does not scale properly without
    //max-width: 120rem;
    margin: 0 auto;
  }
`

export const StyledHeroCircularContent = styled.div`
  max-width: 60rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: ${props => (props.center ? 'center' : 'start')};
  z-index: 2;
  grid-row: 1;
  grid-column: 2/8;
  padding-top: ${props => (props.center ? '' : spacing.xxxLarge)};

  @media ${mq.mobile} {
    /* padding: ${spacing.xLarge} ${spacing.medium}; */
    padding: ${spacing.xSmall} ${spacing.medium} ${spacing.xLarge} ${
  spacing.medium
} ;
  }
  @media (min-width: 1220px) {
    grid-column: 3/8;
    justify-content: end;
  }

  ${StyledText} {
    margin-bottom: ${spacing.large};
    line-height: 1.5;
    @media ${mq.mobile} {
     // font-size: ${typography.sizes.small};
      font-size: 1.5rem;
    }
  }
  ${StyledButton.a} {
    width: fit-content;
    @media ${mq.mobile} {
      padding: ${spacing.small} ${spacing.base};
    }
  }
  ${StyledHeading.h1} {
    margin-bottom: ${spacing.large};
    line-height: 1.1;
  }

  /** Theme styling */

  ${props =>
    props.theme === 'pink' &&
    css`
      ${StyledHeroQuote}, ${StyledHeading.h1} {
        color: ${colors.lightGreen_02};
      }
      ${StyledText} {
        color: ${colors.darkGreen};
      }
      ${StyledButton.a} {
        background-color: ${colors.brightYellow};
        color: ${colors.lightGreen_02};
        &:hover {
          background-color: ${props =>
            props ? darken(0.1, colors.brightYellow) : ''};
          border-color: ${props =>
            props ? darken(0.1, colors.brightYellow) : ''};
          color: ${props => (props ? darken(0.1, colors.lightGreen_02) : '')};
        }
      }
      ${StyledHeroQuoteAuthor} {
        color: ${colors.lightGreen_02};
      }
    `}
  ${props =>
    props.theme === 'green' &&
    css`
      ${StyledHeroQuote}, ${StyledHeading.h1} {
        color: ${colors.brightYellow};
      }

      ${StyledButton.a} {
        background-color: ${colors.pink};
        color: ${colors.darkGreen};
        &:hover {
          background-color: ${props => (props ? darken(0.1, colors.pink) : '')};
          border-color: ${props => (props ? darken(0.1, colors.pink) : '')};
          color: ${props => (props ? darken(0.1, colors.darkGreen) : '')};
        }
      }
      ${StyledHeroQuoteAuthor} {
        color: ${colors.brightYellow};
      }
    `}
  ${props =>
    props.theme === 'blue' &&
    css`
      ${StyledHeroQuote}, ${StyledHeading.h1} {
        color: ${colors.brightYellow};
      }

      ${StyledButton.a} {
        background-color: ${colors.lightBlue};
        color: ${colors.darkestBlue};
        &:hover {
          background-color: ${props =>
            props ? darken(0.1, colors.lightBlue) : ''};
          border-color: ${props =>
            props ? darken(0.1, colors.darkestBlue) : ''};
          color: ${props => (props ? darken(0.1, colors.darkestBlue) : '')};
        }
      }
      ${StyledHeroQuoteAuthor} {
        color: ${colors.brightYellow};
      }
    `}
  ${props =>
    props.theme === 'standard' &&
    css`
      ${StyledHeroQuote}, ${StyledHeading.h1} {
        color: ${colors.white};
      }

      ${StyledButton.a} {
        background-color: ${colors.secondary};
        color: ${colors.primary};
        &:hover {
          background-color: ${props =>
            props ? darken(0.1, colors.secondary) : ''};
          border-color: ${props =>
            props ? darken(0.1, colors.secondary) : ''};
          color: ${props => (props ? darken(0.1, colors.primary) : '')};
        }
      }
      ${StyledHeroQuoteAuthor} {
        color: ${colors.white};
      }
    `}
`

export const StyledHeroQuote = styled.h1`
  color: 'white';
  font-size: ${typography.sizes.xLarge};
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: ${spacing.base};
  @media ${mq.mobile} {
    font-size: ${typography.sizes.medium};
  }
`

export const StyledHeroQuoteAuthor = styled.figcaption`
  margin-bottom: ${spacing.large};
  font-size: ${typography.sizes.base};
  color: ${colors.white};
  line-height: 1.5;
  @media ${mq.mobile} {
    font-size: ${typography.sizes.xxSmall};
  }
`

export const StyledHeroCircularWrapper = styled.div`
  width: 100%;
  min-height: 90rem;
  // max-height: 98rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  //aspect-ratio: 1440/980;
  //  display: flex;
  position: relative;
  justify-content: center;
  overflow-x: hidden;
  grid-row: 1;
  grid-column: 1/13;

  @media (min-width: 1220px) {
    overflow-x: unset;
  }

  @media ${mq.tablet} {
    min-height: 58rem;
  }
  @media (max-width: 480px) {
    /* min-height: 46rem; */
    min-height: 30rem;
  }

  ${props =>
    props.theme === 'pink' &&
    css`
      ${StyledHeroCircularPrimary} {
        background-color: ${colors.pink};
      }
      ${StyledHeroCircularSecondary} {
        background-color: ${colors.darkPink};
      }
    `}
  ${props =>
    props.theme === 'green' &&
    css`
      ${StyledHeroCircularPrimary} {
        background-color: ${colors.forestGreen};
      }
      ${StyledHeroCircularSecondary} {
        background-color: ${colors.lightGreen_02};
      }
    `}
  ${props =>
    props.theme === 'standard' &&
    css`
      ${StyledHeroCircularPrimary} {
        background-color: ${colors.standardTheme.primary};
      }
      ${StyledHeroCircularSecondary} {
        background-color: ${colors.standardTheme.secondary};
      }
    `}

    ${props =>
      props.theme === 'blue' &&
      css`
        ${StyledHeroCircularPrimary} {
          background-color: ${colors.brandBlue};
        }
        ${StyledHeroCircularSecondary} {
          background-color: ${colors.blue};
        }
      `}
`

export const StyledHeroCircularPrimary = styled.div`
  width: 90%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -10rem;
  aspect-ratio: 4/3;
  border-radius: 0 0 50% 50%;
  min-width: 120rem;

  @media ${mq.mobile} {
    min-width: 44rem;
  }
  @media ${mq.mobileSmall} {
    left: -6rem;
    min-width: 37rem;
    width: 37rem;
  }

  @media (max-width: 418px) {
    left: -10rem;
  }

  @media (min-width: 1220px) {
    border-radius: 50%;
    aspect-ratio: 1/1;
    grid-template-columns: 1/9;
    top: unset;
    bottom: 0;
    left: -200px;
  }
`
export const StyledHeroCircularSecondary = styled.div`
  width: 50%;
  aspect-ratio: 1/1;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0% 0% 0% 100%;
  min-width: 26rem;
  //max-width: 64rem;
  @media (max-width: 480px) {
    width: 26rem;
    top: -6rem;
  }
  @media (max-width: 418px) {
  }

  @media (min-width: 1220px) {
    border-radius: 50%;
    aspect-ratio: 1/1;
    grid-column-start: 7;
    top: unset;
    bottom: 26rem;
    right: -400px;
    grid-column: 7/13;
    width: 100%;
    min-width: 110rem;
  }
`
export const StyledHeroCircularThird = styled.div`
  width: 50%;
  position: absolute;
  bottom: 0;
  right: -8rem;
  aspect-ratio: 1/1;
  border-radius: 100%;
  overflow: hidden;
  min-width: 44rem;
  max-width: 54rem;
  @media (max-width: 480px) {
    min-width: unset;
    /* width: 34rem; */
    width: 25.3rem;
    top: 4rem;
    right: -4rem;
    //bottom: 2rem;
  }
  @media (max-width: 418px) {
    right: -6rem;
  }
  @media (min-width: 1220px) {
    grid-column-start: 8;
    right: unset;
    min-width: 56rem;
  }
`
