import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  StyledTextMediaContainer,
  StyledTextMediaContent,
  StyledTextMedia,
} from './style'
import { Image } from '../Image'
import { getUrl } from '../../helpers/url'
import { Circles } from '../Circle'
import { Text } from '../Text'
import { Heading } from '../Heading'
import { Button } from '../Button'

const TextMedia = props => {
  const {
    layout,
    title,
    subtitle,
    titleSize,
    text,
    link,
    image,
    randomImage,
    mirrored,
    theme,
    overflow,
  } = props

  const align = mirrored ? 'left' : 'right'

  return (
    <StyledTextMedia>
      <StyledTextMediaContainer
        mirrored={mirrored}
        layout={layout}
        overflow={overflow}
      >
        <StyledTextMediaContent theme={theme} size={titleSize}>
          {title && (
            <Heading type={'h3'} size={titleSize}>
              {title}
            </Heading>
          )}
          {subtitle && (
            <Heading type={'h3'} size={titleSize}>
              {subtitle}
            </Heading>
          )}
          {text && <Text>{text}</Text>}
          {link && (
            <Button to={getUrl(link.url)} themeColor={theme}>
              {link.title}
            </Button>
          )}
        </StyledTextMediaContent>
        {layout === 'regular' && image && (
          <Image {...image} altText={image.altText} />
        )}
        {layout === 'circular' && (
          <Circles
            align={align}
            themeColor={theme}
            invertColor={overflow ? true : false}
            noContainer
            mirrored={mirrored}
            overflow={overflow}
            hasImage={randomImage || image ? true : false}
          >
            {randomImage && (
              <Image {...randomImage} altText={randomImage.altText} />
            )}
            {!randomImage && image && (
              <Image {...image} altText={image.altText} />
            )}
          </Circles>
        )}
      </StyledTextMediaContainer>
    </StyledTextMedia>
  )
}

TextMedia.propTypes = {
  imageSrc: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.object,
  mirrored: PropTypes.bool,
  theme: PropTypes.string,
}

export { TextMedia }
