import React from 'react'
import PropTypes from 'prop-types'
import StyledHeroDuo from './style'
import { Image } from '../Image'
import { Button } from '../Button'
import { Divider } from '../Divider'
import { Heading } from '../Heading'
import { Grid } from '../Grid'
import { SPACING } from '../../helpers/constants'
import { getUrl } from '../../helpers/url'
import { Video } from '../Video'

const HeroDuo = ({
  critical,
  firstHeading,
  firstPreHeading,
  firstMediaType,
  firstVideo,
  firstImage,
  firstLinkUrl,
  firstLinkTitle,
  firstLinkTargetBlank,
  firstBackgroundColor,
  firstButtonColor,
  firstColorTheme,
  firstOverlay,
  firstOverlayValue,
  secondHeading,
  secondPreHeading,
  secondMediaType,
  secondVideo,
  secondImage,
  secondLinkUrl,
  secondLinkTitle,
  secondLinkTargetBlank,
  secondBackgroundColor,
  secondButtonColor,
  secondColorTheme,
  secondOverlay,
  secondOverlayValue,
}) => {
  return (
    <StyledHeroDuo>
      <Grid col={2} responsive>
        <StyledHeroDuo.FirstCol
          backgroundColor={firstBackgroundColor}
          colorTheme={firstColorTheme}
        >
          {firstMediaType === 'video' && firstVideo && (
            <StyledHeroDuo.Video>
              <Video src={firstVideo.mediaItemUrl} />
            </StyledHeroDuo.Video>
          )}
          {firstMediaType === 'image' && firstImage && (
            <StyledHeroDuo.Image>
              <Image {...firstImage} critical objectFit />
            </StyledHeroDuo.Image>
          )}
          {firstOverlay && <StyledHeroDuo.Overlay value={firstOverlayValue} />}
          <StyledHeroDuo.Body mediaType={firstMediaType}>
            {firstPreHeading && (
              <StyledHeroDuo.PreHeading>
                {firstPreHeading}
              </StyledHeroDuo.PreHeading>
            )}
            {firstPreHeading && firstHeading && <Divider size={SPACING.BASE} />}
            {firstHeading && (
              <Heading type={'h1'} align={'center'}>
                {firstHeading}
              </Heading>
            )}
            {firstLinkUrl && firstHeading && <Divider size={SPACING.BASE} />}
            {firstLinkUrl && firstLinkTitle && (
              <Button
                secondary
                to={getUrl(firstLinkUrl)}
                target={firstLinkTargetBlank}
                themeColor={firstButtonColor}
              >
                {firstLinkTitle}
              </Button>
            )}
          </StyledHeroDuo.Body>
        </StyledHeroDuo.FirstCol>

        <StyledHeroDuo.SecondCol
          backgroundColor={secondBackgroundColor}
          colorTheme={secondColorTheme}
        >
          {secondMediaType === 'video' && secondVideo && (
            <StyledHeroDuo.Video>
              <Video src={secondVideo.mediaItemUrl} />
            </StyledHeroDuo.Video>
          )}
          {secondMediaType === 'image' && secondImage && (
            <StyledHeroDuo.Image>
              <Image {...secondImage} critical objectFit />
            </StyledHeroDuo.Image>
          )}
          {secondOverlay && (
            <StyledHeroDuo.Overlay value={secondOverlayValue} />
          )}
          <StyledHeroDuo.Body>
            {secondPreHeading && (
              <StyledHeroDuo.PreHeading>
                {secondPreHeading}
              </StyledHeroDuo.PreHeading>
            )}
            <Divider size={SPACING.BASE} />
            <Heading type={'h1'} align={'center'}>
              {secondHeading}
            </Heading>
            <Divider size={SPACING.BASE} />
            {secondLinkUrl && secondLinkTitle && (
              <Button
                secondary
                to={getUrl(secondLinkUrl)}
                target={secondLinkTargetBlank}
                themeColor={secondButtonColor}
              >
                {secondLinkTitle}
              </Button>
            )}
          </StyledHeroDuo.Body>
        </StyledHeroDuo.SecondCol>
      </Grid>
    </StyledHeroDuo>
  )
}

HeroDuo.propTypes = {
  firstHeading: PropTypes.string,
  secondHeading: PropTypes.string,
  firstPreHeading: PropTypes.string,
  secondPreHeading: PropTypes.string,
  firstImage: PropTypes.object,
  secondImage: PropTypes.object,
  firstLinkUrl: PropTypes.string,
  firstLinkTitle: PropTypes.string,
  secondLinkUrl: PropTypes.string,
  secondLinkTitle: PropTypes.string,
}

export { HeroDuo }
