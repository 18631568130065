import React, { useRef, useEffect, useState } from 'react'
import { useOnScreen } from '../../hooks/useOnScreen'
import PropTypes from 'prop-types'
import StyledOnScreenWrapper from './style'
 
const OnScreenWrapper = ({ children, doRender }) => {
    const [inView, setInView] = useState(false)
    // Ref for the element that we want to detect whether on screen
    const ref = useRef();
    // Call the hook passing in ref and root margin
    // In this case it would only be considered onScreen if more ...
    // ... than 300px of element is visible.
    const onScreen = useOnScreen(ref, '-0px');
    useEffect(() => {
        if (!inView & onScreen) setInView(true)
    }, [onScreen])

    return (
        <StyledOnScreenWrapper ref={ref} onScreen={onScreen}>
           {(inView || doRender) && children}
        </StyledOnScreenWrapper>
    )
}
 
OnScreenWrapper.propTypes = {
    children: PropTypes.any,
    doRender: PropTypes.bool
}
export { OnScreenWrapper }