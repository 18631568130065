import React from 'react'
import PropTypes from 'prop-types'
import StyledTable from './style'

const Table = ({ children }) => {
  return <StyledTable>{children}</StyledTable>
}

Table.Head = ({ children }) => {
  return <StyledTable.Head>{children}</StyledTable.Head>
}

Table.Body = ({ children }) => {
  return <StyledTable.Body>{children}</StyledTable.Body>
}

Table.Tr = ({ children }) => {
  return <StyledTable.Tr>{children}</StyledTable.Tr>
}
Table.Th = ({ children }) => {
  return <StyledTable.Th>{children}</StyledTable.Th>
}

Table.Td = ({ children, label, bold = false }) => {
  return (
    <StyledTable.Td label={label} bold={bold}>
      {children}
    </StyledTable.Td>
  )
}

PropTypes.propTypes = {
  children: PropTypes.any,
  Head: {
    children: PropTypes.any,
  },
  Body: {
    children: PropTypes.any,
  },
  Tr: {
    children: PropTypes.any,
  },
  Th: {
    children: PropTypes.any,
  },
  Td: {
    label: PropTypes.string,
    children: PropTypes.any,
  },
}
export { Table }
