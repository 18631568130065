import React from 'react'
import PropTypes from 'prop-types'
import StyledText from './style'

/**
 * Text
 * @param {*} props
 */
const Text = props => {
  const { children, size, center, color } = props
  return (
    <StyledText size={size} center={center} color={color}>
      {children}
    </StyledText>
  )
}
Text.propTypes = {
  children: PropTypes.any,
  size: PropTypes.string,
  color: PropTypes.string,
  center: PropTypes.bool,
}

export { Text }
