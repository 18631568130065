import React from 'react'
import { Table } from '../Table'
import StyledTableWrapper from './style'

const TableWrapper = ({ rows, foot }) => {
  return (
    <StyledTableWrapper>
      <Table>
        {rows.map((row, key) => {
          const cells = row.cells
          return (
            <Table.Tr key={key}>
              {cells.map((cell, key) => {
                return <Table.Td key={key}>{cell.content}</Table.Td>
              })}
            </Table.Tr>
          )
        })}
        {foot &&
          foot.map((footRow, key) => {
            const footCells = footRow.cells
            return (
              <Table.Tr key={key}>
                {footCells.map((cell, key) => {
                  return (
                    <Table.Td key={key} bold>
                      {cell.content}
                    </Table.Td>
                  )
                })}
              </Table.Tr>
            )
          })}
      </Table>
    </StyledTableWrapper>
  )
}

export { TableWrapper }
