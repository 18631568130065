import React from 'react'
import PropTypes from 'prop-types'
import StyledHeadline2 from './style'

const Headline2 = ({ children, color, center }) => {
  return (
    <StyledHeadline2 color={color} center={center}>
      {children}
    </StyledHeadline2>
  )
}

Headline2.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  center: PropTypes.bool,
}

export { Headline2 }
