import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import StyledAnimatedCounter from './style'

const AnimatedCounter = ({ targetNumber, unit}) => {

  // Settings
  const delay = 10
  const time = 2000

  // Parse value
  let num = targetNumber.toString()

  /* Check if targetNumber is only 1 character in length */
  if(num.length === 1) num = num + ".0"

  const isComma = /[0-9]+,[0-9]+/.test(num)
  if (isComma) num = num.replace(',', '.')
  const isFloat = /^[0-9]+\.[0-9]+$/.test(num)
  const decimalPlaces = isFloat ? (num.split('.')[1] || []).length : 0;

  // Setup
  var divisions = time / delay;
  let numbers = []
  

  // Generate list of incremental numbers to display
  for (let i = divisions; i >= 1; i--) {
    // Preserve as int if input was int
    let newNum = parseInt((num / divisions) * i)

    // Preserve float if input was float
    if (isFloat) {
      newNum = parseFloat((num / divisions) * i).toFixed(decimalPlaces)
    }

    // Preserve commas if input had commas
    if (isComma) {
      while (/(\d+)(\d{3})/.test(newNum.toString())) {
        newNum = newNum.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2')
      }
    }

    if (['1.0', '2.0'].includes(newNum)) newNum = parseInt(newNum)

    numbers.unshift(newNum)
  }


  // State
  const [index, setIndex] = useState(0)
  const [grow, setGrow] = useState(false)

  /**
   * Run count up
   */
  useEffect(() => {
    setTimeout(() => {
      if (index < (numbers.length -1)) setIndex(index + 1)
      else setGrow(true)
    }, delay)
  }, [index])

  return (
    <StyledAnimatedCounter>
      <StyledAnimatedCounter.Number grow={grow}>
        {numbers[index]}
      </StyledAnimatedCounter.Number>
      <StyledAnimatedCounter.Unit>{unit}</StyledAnimatedCounter.Unit>
    </StyledAnimatedCounter>
  )
}

AnimatedCounter.propTypes = {
  targetNumber: PropTypes.number,
  unit: PropTypes.string,
}
export { AnimatedCounter }
