import React from 'react'
import PropTypes from "prop-types"
import StyledImageCardContentWrapper from './style'

const ImageCardContentWrapper = ({ children, contentAlignment }) => {
    return (
        <StyledImageCardContentWrapper contentAlignment={contentAlignment}>
            {children}
        </StyledImageCardContentWrapper>
    );
}

ImageCardContentWrapper.propTypes = {
    children: PropTypes.any
}

export { ImageCardContentWrapper }