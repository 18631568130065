import React from 'react'
import PropTypes from 'prop-types'
import StyledMediaTextShortcut from './style'
import { Link } from '../Link'
import { Image } from '../Image'

const MediaTextShortcut = props => {
  const {
    children,
    imageSrc,
    image,
    mediaPosition = 'right',
    objectFitHeight = 480,
    objectPosition,
    to,
    alt,
    vertical,
  } = props

  let horizontal = mediaPosition === 'top' || mediaPosition === 'bottom'
  let imageFirst = mediaPosition === 'top' || mediaPosition === 'left'
  let order = imageFirst ? 'image text' : 'text image'
  let mobileObjectFitHeight = (objectFitHeight * 0.2).toString() + 'px'

  const Component = horizontal
    ? StyledMediaTextShortcut.Wrapper
    : StyledMediaTextShortcut

  const isVideo =
    (imageSrc && imageSrc.indexOf('.mov') >= 0) || imageSrc.indexOf('.mp4') >= 0
  const imageFigure = isVideo ? (
    <video src={`${imageSrc}#t=0.001`} controls />
  ) : (
    <Image {...image} altText={alt} />
  )

  return (
    <Component order={order} imageFirst={imageFirst} vertical={vertical}>
      {imageSrc && (
        <StyledMediaTextShortcut.ImageContainer
          horizontal={horizontal}
          objectPosition={objectPosition}
          vertical={vertical}
        >
          {to && <Link to={to}>{imageFigure}</Link>}
          {!to && imageFigure}
        </StyledMediaTextShortcut.ImageContainer>
      )}
      <StyledMediaTextShortcut.TextContainer
        horizontal={horizontal}
        vertical={vertical}
      >
        {children}
      </StyledMediaTextShortcut.TextContainer>
    </Component>
  )
}

MediaTextShortcut.propTypes = {
  children: PropTypes.any,
  href: PropTypes.string,
  imageSrc: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.string,
  linkText: PropTypes.string,
  mediaPosition: PropTypes.string,
}

export { MediaTextShortcut }
