import React, { useEffect, useState } from 'react'
import { TESTFREAKS_CLIENT_ID } from '../../../node/functions'

const TestfreaksSchema = () => {
  const [tfData, setTfData] = useState(null)

  /**
   * Fetch Testfreaks data
   */
  const fetchTfData = async () => {
    try {
      // "newbodyfamily.com-admins" has data from the testfreaks API,
      // needs to be different from main client
      const apiUrl = `https://cr.testfreaks.com/seller_reviews.json?client_id=newbodyfamily.com-admins`
      const data = await fetch(apiUrl).then(response => response.json())
      if (data.avg_score) {
        setTfData(data)
      }
    } catch (error) {
      console.log('error fetching testfreaks data:', error)
    }
  }

  useEffect(() => {
    if (TESTFREAKS_CLIENT_ID) {
      fetchTfData()
    }
  }, [])

  const schema = {
    '@context': 'http://schema.org',
    '@type': 'Product',
    name: 'Tjäna pengar till klassen',
    description:
      'Tjäna pengar till laget, föreningen eller klassen - Newbody Family',
    brand: {
      '@type': 'Brand',
      name: 'Newbody Family',
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: tfData?.avg_score,
      reviewCount: tfData?.review_count,
    },
  }

  return (
    <>
      {tfData && (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(schema),
          }}
        ></script>
      )}
    </>
  )
}

export { TestfreaksSchema }
