import styled, { css } from 'styled-components'
import {
  colors,
  typography,
  spacing,
  maxWidths,
  mq,
} from '../../styles/utilities/variables'

const StyledQuote = styled.blockquote`
  padding: ${spacing.large} 0;
  border-top: 1px solid ${colors.borderColor};
  border-bottom: 1px solid ${colors.borderColor};
  max-width: ${maxWidths.base};
  margin: ${spacing.base} auto;
  display: ${props => (props.center ? '' : 'inline-block')};
  margin-bottom: ${spacing.xLarge};

  ${props =>
    props.large &&
    css`
      border-top: unset;
      border-bottom: unset;
      margin-bottom: ${spacing.xLarge};
      padding-bottom: 0;
      @media ${mq.mobile} {
        padding: 0;
        margin-top: 0;
        margin-bottom: ${spacing.small};
      }
    `}
  ${props =>
    props.large &&
    props.center &&
    css`
      max-width: ${maxWidths.container};
    `}
`

StyledQuote.Content = styled.div`
  p {
    font-size: ${props =>
      props.large ? typography.sizes._88 : typography.sizes.medium};
    font-family: ${typography.secondaryFont};
    font-weight: bold;
    text-align: center;
    margin-bottom: 0;
    line-height: ${props => (props.large ? 1.1 : 1.4)};
    //@TODO chang this out when colors is implementet for Quote
    color: ${props => (props.large ? colors.darkPink : '')};
    @media ${mq.mobile} {
      font-size: ${props =>
        props.large ? typography.sizes.large : typography.sizes.small};
    }
  }
`

StyledQuote.Author = styled.cite`
  margin-top: ${props => (props.large ? spacing.midBase : '')};
  color: ${colors.grey};
  text-align: center;
  display: block;
  padding: 0;
  font-style: ${props => (props.large ? 'normal' : 'italic')};
  font-size: ${props => (props.large ? typography.sizes.base : '')};
  color: ${props => (props.large ? colors.darkPink : '')};
  @media ${mq.mobile} {
    font-size: ${typography.sizes.small};
    margin-top: ${props => (props.large ? spacing.small : '')};
  }
`

export default StyledQuote
