import React, { Fragment, useState, useEffect, useRef } from 'react'
import { StyledCircleContainer, StyledCircle, StyledSmallCircle } from './style'
import { Heading } from '../Heading'
import { AnimatePresence } from 'framer-motion'
import { useResponsive } from '../../hooks/useResponsive'

const Circle = props => {
  const { width, align, themeColor, invertColor, children } = props

  return (
    <StyledCircleContainer>
      <StyledCircle
        width={width}
        align={align}
        themeColor={themeColor}
        invertColor={invertColor}
      >
        {children}
      </StyledCircle>
    </StyledCircleContainer>
  )
}

const Circles = props => {
  const {
    width,
    align,
    themeColor,
    invertColor = false,
    noContainer,
    children,
    title,
    moveUp,
    overflow,
    mirrored,
    translateXDisabled = false,
    hasImage = false,
  } = props
  const ref = useRef(null)
  const smallCircleRef = useRef(null)
  const [inView, setInView] = useState(false)
  const [smallCInView, setsmallCInView] = useState(false)
  const [windowScroll, setWindowScroll] = useState(null)
  const [distance, setDistance] = useState(null)
  const { windowSize, breakpoints } = useResponsive()

  const Component = noContainer ? Fragment : StyledCircleContainer
  const handleScroll = () => {
    setWindowScroll(window.scrollY)
  }

  useEffect(() => {
    if (ref.current) {
      const io = new IntersectionObserver(e => {
        const entry = e.pop()
        if (entry.isIntersecting) {
          setInView(true)
        } else {
          setInView(false)
        }
      })

      io.observe(ref.current)

      handleScroll()

      window.addEventListener('scroll', handleScroll)

      return () => window.removeEventListener('scroll', handleScroll)
    }
    if (smallCircleRef.current) {
      const io = new IntersectionObserver(e => {
        const entry = e.pop()
        if (entry.isIntersecting) {
          setsmallCInView(true)
        } else {
          setsmallCInView(false)
        }
      })

      io.observe(smallCircleRef.current)

      handleScroll()

      window.addEventListener('scroll', handleScroll)

      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [ref])

  useEffect(() => {
    const middle = windowScroll + windowSize.height / 2
    if (inView) {
      const distanceInPixels =
        middle - (ref.current.offsetTop + ref.current.clientHeight / 2)
      setDistance(
        distanceInPixels /
          (windowSize.height / 2 + ref.current.clientHeight / 2)
      )
    }
    if (smallCInView) {
      const distanceInPixels =
        middle -
        (smallCircleRef.current.offsetTop +
          smallCircleRef.current.clientHeight / 2)
      setDistance(
        distanceInPixels /
          (windowSize.height / 2 + smallCircleRef.current.clientHeight / 2)
      )
    }
  }, [windowScroll])

  /**
   * X-axis values for circles
   *
   */
  const [xValue, setXValue] = useState(null)
  const [xValueSmallCircle, setXValueSmallCircle] = useState(null)
  const [overflowXValue, setOverflowXValue] = useState(null)

  useEffect(() => {
    /** remove the translateX from component */
    if (translateXDisabled) {
      if (align === 'left') {
        if (breakpoints.mobile) {
          setXValue(-100)
          setXValueSmallCircle(100)
        } else {
          setXValue(0)
          setXValueSmallCircle(0)
        }
      } else {
        if (breakpoints.mobile) {
          setXValue(100)
          setXValueSmallCircle(-100)
        } else {
          setXValue(0)
          setXValueSmallCircle(0)
        }
      }
    } else {
      /** Layout for when component is last on page */
      if (overflow) {
        if (mirrored) {
          breakpoints.tablet ? setOverflowXValue(100) : setOverflowXValue(-200)
        } else {
          breakpoints.tablet ? setOverflowXValue(100) : setOverflowXValue(380)
        }
      } else {
        if (mirrored || align === 'left') {
          if (breakpoints.tablet) {
            setXValue(-100)
            setXValueSmallCircle(100)
          } else {
            setXValue(-300)
            setXValueSmallCircle(-100)
          }
        } else {
          if (breakpoints.tablet) {
            setXValue(100)
            setXValueSmallCircle(-100)
          } else {
            setXValue(450)
            setXValueSmallCircle(100)
          }
        }
      }
    }
  }, [breakpoints.tablet, breakpoints.mobile])
  const componentProps = !noContainer ? { moveUp: moveUp } : {}
  return (
    <AnimatePresence>
      <Component {...componentProps}>
        <StyledCircle
          width={width}
          align={align}
          themeColor={themeColor}
          invertColor={invertColor}
          noContainer={noContainer}
          ref={ref}
          initial={{ translateY: 0 }}
          animate={
            overflow
              ? {
                  translateY: breakpoints.mobile ? 0 : 70,
                  translateX: overflowXValue,
                }
              : { translateY: -2 * distance, translateX: xValue }
          }
          exit={{ opacity: 0 }}
        />
        <StyledSmallCircle
          align={align}
          themeColor={themeColor}
          invertColor={invertColor}
          noContainer={noContainer}
          ref={smallCircleRef}
          initial={{ translateY: 0 }}
          animate={
            overflow
              ? { translateY: breakpoints.mobile ? 20 : 80, translateX: -10 }
              : { translateY: 2 * distance, translateX: xValueSmallCircle }
          }
          exit={{ opacity: 0 }}
          hasImage={hasImage}
        >
          {children}
        </StyledSmallCircle>
        {title && (
          <Heading
            type='h3'
            color={themeColor === 'blueTheme' ? 'darkPink' : 'lightGreen_02'}
          >
            {title}
          </Heading>
        )}
      </Component>
    </AnimatePresence>
  )
}

export { Circle, Circles }
