import styled from 'styled-components'
import { colors } from "../../styles/utilities/variables";
 
const StyledIconLinkList = styled.ul`
    list-style-type: none;
    display: inline-block;
    display: flex;
    justify-content: center;

    i {
        color: ${colors.white}
    }
`
 
export default StyledIconLinkList