import styled, { css } from 'styled-components'
import {
  colors,
  spacing,
  mq,
  typography,
} from '../../styles/utilities/variables'

const StyledTable = styled.table`
  border-collapse: collapse;

  @media ${mq.mobile} {
    border: none;
  }
`

StyledTable.Head = styled.thead`
  @media ${mq.mobile} {
    left: -9999px;
    position: absolute;
    visibility: hidden;
  }
  th {
    background-color: ${colors.grey};
  }
`

StyledTable.Body = styled.tbody``

StyledTable.Tr = styled.tr``

StyledTable.Th = styled.th`
  padding: ${spacing.medium} ${spacing.base};
  font-weight: bold;
  vertical-align: middle;
  border: 1px solid ${colors.borderColor};
`

StyledTable.Td = styled.td`
  text-align: left;
  padding: ${spacing.xSmall} ${spacing.medium};
  font-size: ${typography.sizes.base};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};

  &:last-child {
    text-align: left;
  }
  vertical-align: middle;

  ${props =>
    props.label &&
    css`
      .label {
        background-color: ${colors.lightGrey};
        color: ${colors.black};
        display: none;
        font-size: ${typography.sizes.xSmall};
        font-weight: bold;
        padding: ${spacing.xSmall} ${spacing.small};
        position: absolute;
        letter-spacing: 0.5px;
        top: 0;
        left: 0;
      }
      @media ${mq.mobile} {
        padding-top: ${spacing.large};
        .label {
          display: block;
        }
      }
    `}
`

export default StyledTable
