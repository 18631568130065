import React from 'react'
import PropTypes from 'prop-types'
import StyledIconListItem from './style'
import { Headline2 } from '../Text/Headline2'
import { Icon } from '../Icon'
import { Divider } from '../Divider'
import { colors } from '../../styles/utilities/variables'
import { Content } from '../Content'
import { getUrl } from '../../helpers/url'

const IconListItem = ({ item }) => {
  return (
    <StyledIconListItem>
      <StyledIconListItem.Link to={getUrl(item.href)}>
        <StyledIconListItem.IconWrapper>
          <Icon icon={item.icon} />
        </StyledIconListItem.IconWrapper>
        <Divider size='medium' />
        <Headline2 color={colors.primary} center>
          {item.title}
        </Headline2>
        <Content>
          <StyledIconListItem.Text>{item.preamble}</StyledIconListItem.Text>
        </Content>
      </StyledIconListItem.Link>
    </StyledIconListItem>
  )
}

IconListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    to: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    preamble: PropTypes.string,
  }),
}
export { IconListItem }
