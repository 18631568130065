import React from 'react'
import {
  StyledCalculatorCircle,
  StyledCalculatorContainer,
  StyledRevenueHeading,
  StyledCalculatorGrid,
} from './style'

import { CalculatorForm } from './CalculatorForm'
import { Heading } from '../Heading'
import { Text } from '../Text'
import { Wrapper } from '../Wrapper'
import { Revenue } from '../Revenue'
import { Content } from '../Content'

const Calculator = props => {
  const { title, text, currency, revenues, revenueText, themeColor } = props
  const headingColor = themeColor === 'blueTheme' ? 'pink' : 'brightYellow'
  const textColor = themeColor === 'blueTheme' ? 'white' : 'lightYellow'

  return (
    <StyledCalculatorContainer
      fullScreen
      noPadding
      overflow
      linearBackground
      themeColor={themeColor}
    >
      <StyledCalculatorGrid>
        <StyledCalculatorCircle
          width={'99.5'}
          color={themeColor === 'blueTheme' ? 'brandBlue' : null}
        ></StyledCalculatorCircle>
        <StyledCalculatorCircle
          width={'98.5'}
          color={themeColor === 'blueTheme' ? 'blue' : 'lightGreen_02'}
        />
        <CalculatorForm
          currency={currency}
          revenues={revenues}
          themeColor={themeColor}
        />
        {title ? (
          <StyledRevenueHeading>
            <Content>
              {title && (
                <Heading type='h4' size={'xLarge'} color={headingColor}>
                  {title}
                </Heading>
              )}
              {text && <Text color={textColor}>{text}</Text>}
            </Content>
          </StyledRevenueHeading>
        ) : null}
        {revenues ? (
          <Wrapper flexWrap columnGap={'xxxLarge'} rowGap={'xxLarge'}>
            {revenues?.map(revenue => {
              return (
                <Revenue
                  profit={revenue.profit}
                  cost={revenue.priceKr}
                  text={revenueText}
                  currency={currency}
                  themeColor={themeColor}
                />
              )
            })}
          </Wrapper>
        ) : null}
      </StyledCalculatorGrid>
    </StyledCalculatorContainer>
  )
}

export { Calculator }
