import styled from 'styled-components'
import { colors } from '../../styles/utilities/variables'

export const StyledTestFreaks = styled.div`
  #testfreaks-all-reviews {
    .bin {
      background-color: ${colors.black} !important;
    }
  }
`
