import { useState, useEffect } from 'react'

//
// Totally stolen from https://usehooks.com/useOnScreen/
//
const useOnScreen = (ref, rootMargin = '0px') => {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    if (typeof IntersectionObserver !== 'undefined') {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIntersecting(entry.isIntersecting)
        },
        {
          rootMargin,
        }
      )
      if (ref.current) {
        observer.observe(ref.current)
      }
      return () => {
        observer.unobserve(ref.current)
      }
    } else {
        setIntersecting(true)
    }
  }, [])

  return isIntersecting
}

export { useOnScreen }
