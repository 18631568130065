import React from 'react'
import PropTypes from 'prop-types'
import StyledSplash from './style'
 
const Splash = ({children, alignRight, pushUp, alignToBottom, parentIsVertical}) => {
    return (
        <StyledSplash alignRight={alignRight} pushUp={pushUp} alignToBottom={alignToBottom} parentIsVertical={parentIsVertical}>
            {children}
        </StyledSplash>
    )
}
 
Splash.propTypes = {
    children: PropTypes.any,
    alignRight: PropTypes.bool
}
export { Splash }