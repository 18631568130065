import { FONT_SIZE } from './constants'

export const getFontSize = fontSize => {
  let size = null

  switch (fontSize) {
    case 'xxl':
      size = FONT_SIZE.XXX_LARGE
      break
    case 'xl':
    case 1:
      size = FONT_SIZE.XX_LARGE
      break
    case 'l':
    case 2:
      size = FONT_SIZE.X_LARGE
      break
    case 3:
      size = FONT_SIZE.LARGE
      break
    case 'm':
    case 4:
      size = FONT_SIZE.MEDIUM
      break
    case 5:
      size = FONT_SIZE.BASE
      break
    case 's':
    case 6:
      size = FONT_SIZE.SMALL
      break
    default:
      size = FONT_SIZE.BASE
  }
  return size
}
