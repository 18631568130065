import styled from 'styled-components'
import StyledIcon from '../Icon/style'

const StyledAlignWrapper = styled.div`
  text-align: ${props => (props.align ? props.align : 'left')};
  ${StyledIcon} {
    display: inline-block;
  }
`

export default StyledAlignWrapper
