import styled from "styled-components"
import { colors, spacing } from "../../styles/utilities/variables"

const getAlign = (align) => {
  const value = {
    left: 0,
    center: 'auto'
  }
  return value[align]
}

const StyledLoader = styled.div`
  width: 40px;
  height: 40px;

  position: relative;
  margin: ${props => (props.spacing ? spacing.large : 0)} ${props => props.align ? getAlign(props.align) : 'auto'};


  div {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${colors.primary};
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
  }
  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
`

StyledLoader.Bounce1 = styled.div``
StyledLoader.Bounce2 = styled.div`
  && {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
`

export default StyledLoader
