import React from 'react'
import { Form } from '../../components/Form'
import { Button } from '../../components/Button'
import { Heading } from '../../components/Heading'
import { COLORS } from '../../helpers/constants'
import { AlignWrapper } from '../../components/AlignWrapper'
import { getString } from '../../helpers/lang'
import { Icon } from '../../components/Icon'
import { Preamble } from '../../components/Text/Preamble'
import { Content } from '../../components/Content'
import { Divider } from '../../components/Divider'

/**
 * Error
 */
const Error = () => {
  return (
    <Content align={'center'}>
      <AlignWrapper align={'center'}>
        <Icon color={COLORS.RED} size={90} icon={'exclamation-circle'} />
      </AlignWrapper>
      <Divider />
      <Heading align={'center'} type={'h3'}>
        {getString('FORM_ERROR_TITLE')}
      </Heading>
      <Preamble center>
        <span
          dangerouslySetInnerHTML={{ __html: getString('FORM_ERROR_TEXT') }}
        />
      </Preamble>
    </Content>
  )
}

/**
 * Return success
 * @param {*} title
 * @param {*} text
 * @param {*} buttonText
 * @param {*} buttonLink
 */
const ReturnSuccess = ({ title, text, buttonUrl }) => {
  return (
    <Form.Section>
      <Content align={'center'}>
        <Heading color={COLORS.RED} align={'center'} type={'h3'}>
          {title}
        </Heading>
        <Preamble center>{text}</Preamble>
      </Content>
      <AlignWrapper align={'center'}>
        <Button target={'_blank'} to={buttonUrl}>
          {getString('FORM_TICKET_LABEL')}
        </Button>
      </AlignWrapper>
    </Form.Section>
  )
}

/**
 * Maintenance
 */
const Maintenance = () => {
  return (
    <Content align={'center'}>
      <AlignWrapper align={'center'}>
        <Icon color={COLORS.RED} size={90} icon={'exclamation-circle'} />
      </AlignWrapper>
      <Divider />
      <Heading align={'center'} type={'h3'}>
        Tekniskt underhåll
      </Heading>
      <Preamble center>
        Just nu gör vi tekniskt underhåll,<br />var god kom tillbaka lite senare.
      </Preamble>
    </Content>
  )
}

export { Error, ReturnSuccess, Maintenance }
