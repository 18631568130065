import styled from 'styled-components'
import { colors, typography } from '../../styles/utilities/variables'

const StyledPreHeader = styled.p`
  font-size: ${typography.sizes.xSmall};
  font-family: ${typography.secondaryFont};
  line-height: ${typography.sizes.medium};
  text-transform: uppercase;
  margin:0;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: ${props => props.color ?? colors.grey};
  margin-bottom: ${props => props.spaceBelow ? '14px' : '0'};
`

export default StyledPreHeader
