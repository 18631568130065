import React from 'react'
import StyledImageFigure from './style'
import PropTypes from 'prop-types'

const ImageFigure = ({ 
    alt,
    children, 
    imageSrc, 
    imageEffects, 
    id, 
    relative, 
    useMaxWidth=true, 
    objectPosition, 
    objectFitHeight, 
    mobileObjectFitHeight, 
    objectFitAlignLeft, 
    rounded, 
    caption }) => {

    return (
        <StyledImageFigure 
            relative={relative} 
            rounded={rounded} 
            objectFitHeight={objectFitHeight}
            mobileObjectFitHeight={mobileObjectFitHeight}>
            {imageSrc && 
            <StyledImageFigure.Image 
                id={id}
                alt={alt}
                src={imageSrc} 
                rounded={rounded}
                objectPosition={objectPosition}
                objectFitHeight={objectFitHeight}
                objectFitAlignLeft={objectFitAlignLeft}
                mobileObjectFitHeight={mobileObjectFitHeight}
                useMaxWidth={useMaxWidth}
                darken={imageEffects && imageEffects.darken} 
                grayscale={imageEffects && imageEffects.grayscale}
                makeBlackAndWhite={imageEffects && imageEffects.makeBlackAndWhite}/>}
            
            {imageSrc &&
            imageEffects && 
            imageEffects.useBlur && 
            <StyledImageFigure.ImageEffect 
                src={imageSrc} 
                objectFitHeight={objectFitHeight}
                useMaxWidth={useMaxWidth}
                effectDirection={imageEffects.effectDirection}/>}

            {imageEffects && 
            imageEffects.color && 
            <StyledImageFigure.Overlay 
                objectFitHeight={objectFitHeight}
                color={imageEffects.color} 
                effectDirection={imageEffects.effectDirection}/>}
            {children}
            {caption && <StyledImageFigure.Caption>
                {caption}
            </StyledImageFigure.Caption>}
        </StyledImageFigure>
    )
}

ImageFigure.propTypes = {
    children: PropTypes.any,
    imageSrc: PropTypes.string,
    imageEffects: PropTypes.shape({
        useBlur: PropTypes.bool,
        color: PropTypes.string,
        effectDirection: PropTypes.string,
        darken: PropTypes.string,
        grayscale: PropTypes.string,
        makeBlackAndWhite: PropTypes.bool
    }),
    id: PropTypes.string,
    relative: PropTypes.bool
}

export { ImageFigure }