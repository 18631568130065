import React from 'react'
import {
  StyledHeroCircular,
  StyledHeroCircularWrapper,
  StyledHeroCircularContent,
  StyledHeroCircularThird,
  StyledHeroCircularPrimary,
  StyledHeroCircularSecondary,
  StyledHeroQuote,
  StyledHeroQuoteAuthor,
  StyledHeroCircularContainer,
} from './style'
import { Button } from '../Button'
import { Text } from '../Text'
import { Image } from '../Image'
import { Container } from '../Container'
import { Heading } from '../Heading'
import { Video } from '../Video'

const HeroCircular = props => {
  const {
    titleSettings,
    ingress,
    theme,
    image,
    mediaType,
    video,
    buttonCaption,
    buttonHref,
  } = props

  const quote = titleSettings.heroQuote.quote
  const author = titleSettings.heroQuote.quoteAuthor

  return (
    <StyledHeroCircularContainer>
      <StyledHeroCircular theme={theme}>
        <StyledHeroCircularWrapper theme={theme}>
          <StyledHeroCircularPrimary></StyledHeroCircularPrimary>
          <StyledHeroCircularSecondary></StyledHeroCircularSecondary>
          <StyledHeroCircularThird>
            {video && mediaType === 'video' && (
              <Video src={video.mediaItemUrl} />
            )}
            {image && <Image critical {...image} objectFit />}
          </StyledHeroCircularThird>
        </StyledHeroCircularWrapper>
        <StyledHeroCircularContent
          theme={theme}
          center={titleSettings.heroType === 'title'}
        >
          {titleSettings.heroType === 'quote' && (
            <>
              <StyledHeroQuote color={titleSettings.color}>
                {quote}
              </StyledHeroQuote>
              <StyledHeroQuoteAuthor>{author} </StyledHeroQuoteAuthor>
            </>
          )}
          {titleSettings.heroType === 'title' && (
            <Heading type='h1'>{titleSettings.heroTitle}</Heading>
          )}
          {ingress && <Text color={'white'}>{ingress}</Text>}
          {buttonCaption && <Button to={buttonHref}>{buttonCaption} </Button>}
        </StyledHeroCircularContent>
      </StyledHeroCircular>
    </StyledHeroCircularContainer>
  )
}

export { HeroCircular }
