import styled, { css } from 'styled-components'
import {
  spacing,
  typography,
  mq,
  colors,
} from '../../styles/utilities/variables'
import StyledButton from '../Button/style'
import { darken } from 'polished'

const StyledCardFooter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    justify-content: center;
    padding-top:${spacing.large};
    /* padding: ${spacing.small} ${spacing.xLarge} ${spacing.xxxLarge} ${
  spacing.xLarge
}; */

    ${props =>
      props.contentAlignment !== 'center' &&
      css`
        & > * {
          margin: 0 ${spacing.base};
        }
      `}
    ${props =>
      props.contentAlignment === 'center' &&
      css`
        flex-direction: column;
      `}

    ${props =>
      props.theme === 'pink' &&
      css`
        ${StyledButton.a} {
          background-color: ${colors.pink};
          color: ${colors.darkGreen};
          &:hover {
            background-color: ${props =>
              props ? darken(0.1, colors.pink) : ''};
            border-color: ${props => (props ? darken(0.1, colors.pink) : '')};
            color: ${props => (props ? darken(0.1, colors.darkGreen) : '')};
          }
        }
      `}
      ${props =>
        props.theme === 'green' &&
        css`
          ${StyledButton.a} {
            background-color: ${colors.brightYellow};
            color: ${colors.lightGreen_02};
            &:hover {
              background-color: ${props =>
                props ? darken(0.1, colors.brightYellow) : ''};
              border-color: ${props =>
                props ? darken(0.1, colors.brightYellow) : ''};
              color: ${props =>
                props ? darken(0.1, colors.lightGreen_02) : ''};
            }
          }
        `}
      ${props =>
        props.theme === 'blue' &&
        css`
          ${StyledButton.a} {
            background-color: ${colors.lightBlue};
            color: ${colors.darkestBlue};
            &:hover {
              background-color: ${props =>
                props ? darken(0.1, colors.lightBlue) : ''};
              border-color: ${props =>
                props ? darken(0.1, colors.lightBlue) : ''};
              color: ${props => (props ? darken(0.1, colors.darkestBlue) : '')};
            }
          }
        `}

    @media ${mq.tablet} {
        padding-top:${spacing.large};
        padding-bottom:0;
        flex-direction: column;
        & > * {
            margin: 0 0 ${spacing.medium} 0;
            &:last-child {
                margin-bottom:0;
            }
        }
    }
`

StyledCardFooter.ContentWrapper = styled.div``

StyledCardFooter.ContentWrapper.Text = styled.p`
  margin: 0;
  font-size: ${typography.sizes.small};
`

export default StyledCardFooter
