import React, { Children } from 'react'
import PropTypes from 'prop-types'
import { StyledImageCardA, StyledImageCardDiv } from './style'
import { ImageCardContentWrapper } from '../ImageCardContentWrapper'
import { CardFooter } from '../CardFooter'
import { Button } from '../Button'
import { Image } from '../Image'
import { Video } from '../Video'

const ImageCard = ({
  href,
  children,
  height,
  theme,
  mediaType,
  video,
  videoMobile,
  image,
  objectFitHeight,
  contentAlignment,
  buttonCaption,
  buttonHref,
  secondButtonCaption,
  secondButtonHref,
}) => {
  const Card = href ? StyledImageCardA : StyledImageCardDiv

  return (
    <Card
      href={href}
      contentAlignment={contentAlignment}
      height={height}
      objectFitHeight={objectFitHeight}
      mediaType={mediaType}
    >
      {mediaType === 'video' && video && (
        <Video src={video.mediaItemUrl} srcMobile={videoMobile?.mediaItemUrl} />
      )}
      {image && <Image critical {...image} objectFit />}
      <Card.ContentAndButtonWrapper
        subContent={buttonCaption || image}
        mediaType={mediaType}
      >
        <ImageCardContentWrapper contentAlignment={contentAlignment}>
          {children}
        </ImageCardContentWrapper>
        {(buttonCaption || secondButtonCaption) && (
          <CardFooter contentAlignment={contentAlignment} theme={theme}>
            {buttonCaption && <Button to={buttonHref}>{buttonCaption}</Button>}
            {secondButtonCaption && (
              <Button to={secondButtonHref} secondary borderMobile>
                {secondButtonCaption}
              </Button>
            )}
          </CardFooter>
        )}
      </Card.ContentAndButtonWrapper>
    </Card>
  )
}

ImageCard.propTypes = {
  href: PropTypes.string,
  children: PropTypes.any,
  theme: PropTypes.string,
  image: PropTypes.object,
  video: PropTypes.object,
  contentWidth: PropTypes.string,
  contentAlignment: PropTypes.string,
  buttonCaption: PropTypes.string,
  buttonHref: PropTypes.string,
  secondButtonCaption: PropTypes.string,
  secondButtonHref: PropTypes.string,
}

export { ImageCard }
