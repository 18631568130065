import styled from 'styled-components'
import StyledImage from '../Image/style'
import { colors, mq, spacing } from '../../styles/utilities/variables'

export const StyledCtaContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    margin: unset;
  }

  a {
    width: fit-content;
  }

  > :nth-child(1) {
    margin-bottom: 1rem;
  }

  > :nth-child(2) {
    margin-bottom: 2rem;

    @media (max-width: 600px) {
      margin-bottom: ${spacing.base};
    }
  }

  line-height: 1.5;

  a {
    background-color: ${colors.darkGreen};
  }
`

export const StyledCta = styled.div`
  background-color: #f0eee7;
  display: grid;
  grid-template-columns: repeat(10, 1fr);

  @media (max-width: 600px) {
    background-color: unset;
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  ${StyledImage} {
    aspect-ratio: 1;

    img,
    picture {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media (max-width: 600px) {
      aspect-ratio: unset;
      max-height: 15rem;
    }
  }

  &:not(.mirrored) {
    ${StyledImage} {
      grid-column: 1 / 5;

      @media (max-width: 600px) {
        grid-column: 1 / 1;
      }
    }

    ${StyledCtaContent} {
      grid-column: 6 / 10;

      @media (max-width: 600px) {
        grid-column: 1 / 1;
      }
    }
  }

  &.mirrored {
    ${StyledImage} {
      grid-column: 7 / 11;
      order: 2;

      @media (max-width: 600px) {
        order: 1;
        grid-column: 1 / 1;
      }
    }

    ${StyledCtaContent} {
      grid-column: 2 / 6;
      order: 1;

      @media (max-width: 600px) {
        grid-column: 1 / 1;
        order: 2;
      }
    }
  }
`
