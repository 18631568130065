import React from 'react'
import PropTypes from 'prop-types'
import StyledContactCard from './style'
import { ImageFigure } from '../ImageFigure'
import { Divider } from '../Divider'
import { Image } from '../Image'
import { Icon } from '../Icon'
import { getString } from '../../helpers/lang'

const ContactCard = ({ contact }) => {
  return (
    <StyledContactCard>
      <StyledContactCard.Image>
        {contact.image && <Image {...contact.image} />}
        {!contact.image && <Icon size={50} icon={'user'} />}
      </StyledContactCard.Image>
      <Divider size='medium'></Divider>
      <StyledContactCard.Name>{contact.name}</StyledContactCard.Name>
      <Divider size='xxSmall'></Divider>
      {contact.showTitle && contact.title && (
        <>
          <StyledContactCard.Title>{contact.title}</StyledContactCard.Title>
          <Divider size='xSmall'></Divider>
        </>
      )}
      <StyledContactCard.Email href={`mailto:${contact.email}`}>
        {/* <span dangerouslySetInnerHTML={{__html: contact.email.replace('@', '&shy;@')}} /> */}
        <span dangerouslySetInnerHTML={{__html: getString('FORM_EMAIL_LABEL')}} />
      </StyledContactCard.Email>
      <StyledContactCard.Number href={`tel:${contact.number}`}>
        {contact.number}
      </StyledContactCard.Number>
    </StyledContactCard>
  )
}

ContactCard.propTypes = {
  contact: PropTypes.shape({
    image: PropTypes.shape,
    name: PropTypes.string,
    title: PropTypes.string,
    number: PropTypes.string,
    email: PropTypes.string,
  }),
}

export { ContactCard }
