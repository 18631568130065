import styled, { css } from 'styled-components'
import { typography, colors, spacing, mq } from '../../styles/utilities/variables'

const StyledImageFigure = styled.figure`
    height: ${props => props.objectFitHeight ? props.objectFitHeight : 'auto'};
    ${props => !props.relative && css`
        position: absolute;
        z-index: -1;
        top: 0;
        width: 100%;
        min-height: 100%;
    `}
    ${props => props.relative && css`
        position: relative;
    `}
    ${props => props.rounded && css`
        width: ${props => props.objectFitHeight};
    `}
    @media ${mq.tablet} {
        position: relative;
        width: ${props => props.mobileObjectFitHeight};
        height: ${props => props.mobileObjectFitHeight};
    }

`;

StyledImageFigure.Image = styled.img`
    filter: brightness(${props => props.darken ? props.darken : '100%'}) grayscale(${props => props.makeBlackAndWhite ? '100%' : props.grayscale ? props.grayscale: '0%'});
    max-width: ${props => props.useMaxWidth ? '100%' : 'none'};
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    height:100%;
    ${props => props.objectFitHeight !== '-1' && css`
        object-fit: cover;
        object-position: ${props => props.objectFitAlignLeft ? '0 0' : '50% 50%'};
        height: ${props => props.objectFitHeight};
    `}
    ${props => props.rounded && css`
        border-radius: 50%;
    `}
    ${props => props.objectPosition && css`
        object-position: ${props => props.objectPosition};
    `}

    @media ${mq.tablet} {
        height: ${props => props.mobileObjectFitHeight};
    }

`

StyledImageFigure.ImageEffect = styled.img`
    position: absolute;
    left: 0;
    z-index: 1; 
    filter: blur(3px) grayscale(100%);
    mask: linear-gradient(to ${props => props.effectDirection || 'top right'}, black, transparent 80%);
    max-width: ${props => props.useMaxWidth ? '100%' : 'none'};
    min-width: 100%;
    ${props => props.objectFitHeight !== '-1' && css`
        object-fit: cover;
        height: ${props => props.objectFitHeight};
    `}
`

StyledImageFigure.Overlay = styled.div`
    position: absolute;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 99%;
    opacity: 0.5;
    background: linear-gradient(to ${props => props.effectDirection || 'top right'}, ${props => props.color}, transparent 80%);
    min-width: 100%;
    ${props => props.objectFitHeight !== '-1' && css`
        object-fit: cover;
        height: ${props => props.objectFitHeight};
    `}
`

StyledImageFigure.Caption = styled.figcaption`
    font-family: ${typography.primaryFont};
    color: ${colors.graphite};
    margin-top:${spacing.small};
    font-size:${typography.sizes.small};
`

export default StyledImageFigure;

