import React from 'react'
import PropTypes from 'prop-types';
import StyledContent from './style';

/**
 * Content
 * @param {*} param0 
 */
const Content = ({ children, align, id }) => {
    return (
        <StyledContent align={align} id={id}>
            {children}
        </StyledContent>
    )
}
Content.propTypes = {
    children: PropTypes.any,
}

export { Content }