import React, { useEffect, useRef } from 'react'
import useScript from '../../hooks/useScript'
import { GAMIFIERA_MERCHENT_ID } from '../../../node/functions'
import StyledGamifieraCommunityFeed from './style'

const GamifieraCommunityFeed = props => {
  const ref = useRef(null)
  const [loading] = useScript({
    src: GAMIFIERA_MERCHENT_ID
      ? `https://cdn.gamifiera.com/static/gmf/loader.js`
      : null,
  })
  useEffect(() => {
    if (!loading) {
      window.gmf('init', { merchantId: GAMIFIERA_MERCHENT_ID, locale: 'sv_SE' })
    }
  }, [loading])
  return (
    <StyledGamifieraCommunityFeed>
      <div ref={ref} class='gmf-start-page-slideshow'></div>
    </StyledGamifieraCommunityFeed>
  )
}

export { GamifieraCommunityFeed }
