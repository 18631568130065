import styled from 'styled-components'
import { spacing } from '../../styles/utilities/variables'
import StyledButton from '../Button/style'

const StyledShortcutCard = styled.div``
StyledShortcutCard.Body = styled.div`
  > ${StyledButton.a} {
    margin-top: ${spacing.medium};
  }
`

StyledShortcutCard.Title = styled.p`
  font-weight: bold;
`

export default StyledShortcutCard
