import React from 'react'
import PropTypes from 'prop-types'
import StyledContactCardList from './style'
import { ContactCard } from '../ContactCard'
 
const ContactCardList = ({ contacts }) => {
    return ( 
        <StyledContactCardList>
           {contacts.map(contact => (
               <ContactCard key={contact.id} contact={contact}></ContactCard>
           ))}
        </StyledContactCardList>
    )
}
 
ContactCardList.propTypes = {
    contact: PropTypes.array
}
export { ContactCardList }