import styled, { css } from 'styled-components'
import StyledHeading from '../Heading/style'
import StyledText from '../Text/style'
import StyledWrapper from '../Wrapper/style'
import {
  colors,
  typography,
  spacing,
  mq,
} from '../../styles/utilities/variables'

const StyledRevenue = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  column-gap: ${spacing.large};
  z-index: 2;
  margin: 0;

  @media (max-width: 1440px) {
    flex-direction: column;
  }

  .revenue-icon {
    display: flex;
  }

  @media ${mq.tablet} {
    ${StyledWrapper} {
      row-gap: ${spacing.small};
    }
  }

  @media ${mq.mobile} {
    flex-direction: row;
    width: 100%;
    align-self: flex-start;
  }
  & ${StyledHeading.h3} {
    font-family: ${typography.MatrisePro};
    color: ${colors.brightYellow};
    font-weight: 400;
    ${props =>
      props.themeColor === 'blueTheme' &&
      css`
        color: ${colors.pink};
      `}
  }
  & ${StyledText} {
    max-width: 22rem;
    // margin: 0 auto;
    line-height: 1.3;
  }
`

StyledRevenue.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  row-gap: ${spacing.small};
`

export default StyledRevenue
