import React, { useState } from 'react'
import PropTypes from 'prop-types'
import StyledAccordionListItem from './style'
import { ContentExpander } from '../ContentExpander'
import { getUrl } from '../../helpers/url'
import { Animate } from '../Animate'
import { getSvgIcon } from '../../helpers/svgIcons'
import { Text } from '../Text'
import { getString } from '../../helpers/lang'
import { useLocation } from '@reach/router'

const AccordionListItem = ({ item, listSettings }) => {
  const [height, setHeight] = useState('0')
  const [color, setColor] = useState(listSettings.colorFaq)
  const [isOpen, setIsOpen] = useState(false)

  const location = useLocation()

  /**
   * Check if page is tjana-pengar
   */
  const isEarnMoneyPage =
    location.pathname === getString('EARN_MONEY_SLUG') ? 'true' : false

  function setAnimationValues() {
    let animationValue = 0
    setHeight(height === '0' ? animationValue : '0')
    setIsOpen(!isOpen)
  }

  const TextWithLineBreaks = text => {
    const lines = text.split('\n')
    return (
      <>
        {lines.map((line, index) => (
          <Text key={index} size={'small'}>
            {line}
          </Text>
        ))}
      </>
    )
  }

  return (
    <StyledAccordionListItem key={item.id}>
      <StyledAccordionListItem.TitleWrapper
        onClick={() => setAnimationValues()}
      >
        <StyledAccordionListItem.Title
          // animate={{ color: color }}
          initial={false}
          transition={{ duration: 0.25 }}
          color={listSettings.colorFaq}
        >
          {item.title}
        </StyledAccordionListItem.Title>
        <Animate
          size={24}
          to={getSvgIcon('minus', listSettings.colorFaq, 24)}
          from={getSvgIcon('plus', listSettings.colorFaq, 24)}
          animate={isOpen}
        />
      </StyledAccordionListItem.TitleWrapper>
      <ContentExpander open={isOpen}>
        <StyledAccordionListItem.BodyContent>
          {isEarnMoneyPage &&
            item.textEarnMoney &&
            TextWithLineBreaks(item.textEarnMoney)}
          {isEarnMoneyPage &&
            !item.textEarnMoney &&
            TextWithLineBreaks(item.text)}
          {!isEarnMoneyPage && TextWithLineBreaks(item.text)}

          {item.readMore && !isEarnMoneyPage && (
            <StyledAccordionListItem.Link
              to={getUrl(item.href, true)}
              color={listSettings.colorLink}
              primary={listSettings.colorLink === 'filledBeige' ? true : false}
            >
              {item.linkText}
            </StyledAccordionListItem.Link>
          )}
          {item.readMore && isEarnMoneyPage && !item.textEarnMoney && (
            <StyledAccordionListItem.Link
              to={getUrl(item.href, true)}
              color={listSettings.colorLink}
              primary={listSettings.colorLink === 'filledBeige' ? true : false}
            >
              {item.linkText}
            </StyledAccordionListItem.Link>
          )}
        </StyledAccordionListItem.BodyContent>
      </ContentExpander>
    </StyledAccordionListItem>
  )
}

AccordionListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    text: PropTypes.string,
    href: PropTypes.string,
    linkText: PropTypes.string,
  }),
}
export { AccordionListItem }
