import React from 'react'
import { Image } from '../Image'
import { StyledCta, StyledCtaContent } from './style'
import { Heading } from '../Heading'
import { Text } from '../Text'
import { Button } from '../Button'

export const Cta = props => {
  const { image, title, titleColor, description, link, mirrored } = props

  let headingColor

  if (titleColor === 'red') headingColor = 'darkPink'
  if (titleColor === 'green') headingColor = 'lightGreen_02'

  return (
    <StyledCta className={mirrored ? 'mirrored' : null}>
      {image ? <Image {...image} /> : null}

      <StyledCtaContent>
        {title ? <Heading color={headingColor}>{title}</Heading> : null}
        {description ? <Text>{description}</Text> : null}
        {link ? (
          <Button to={link.url} small>
            {link.title}
          </Button>
        ) : null}
      </StyledCtaContent>
    </StyledCta>
  )
}
