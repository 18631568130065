import React from 'react'

import {
  StyledSteps,
  StyledStep,
  StyledNumber,
  StyledNumberDistance,
  StyledNumberContainer,
} from './style'
import { Circle, Circles } from '../Circle'
import { Heading } from '../Heading'
import { Text } from '../Text'
import { Image } from '../Image'

const Steps = props => {
  const { steps, title } = props

  let align = 'left'
  let stepsNumber = 0
  const totalSteps = steps.length

  function isEven(value) {
    if (value % 2 === 0) return (align = 'left')
    else return (align = 'right')
  }

  const themeColor = steps[0].themeColor
  return (
    <StyledSteps>
      <Circles
        align={'left'}
        moveUp
        title={title}
        themeColor={themeColor}
        translateXDisabled
        invertColor
      />
      {steps &&
        steps.map((step, key) => {
          stepsNumber++
          isEven(stepsNumber)

          return (
            <>
              <Step
                key={key}
                stepsNumber={stepsNumber}
                title={step.title}
                text={step.text}
                totalSteps={totalSteps}
                align={align}
                themeColor={step.themeColor}
                invertColor={step.invertColor}
                image={step.image}
              />
            </>
          )
        })}
    </StyledSteps>
  )
}

const Step = props => {
  const {
    stepsNumber,
    title,
    text,
    align,
    totalSteps,
    themeColor,
    invertColor,
    image,
  } = props

  return (
    <StyledStep>
      <StyledNumberContainer>
        <StyledNumber themeColor={themeColor}>
          <span>{stepsNumber}</span>
        </StyledNumber>
        <StyledNumberDistance themeColor={themeColor} />
      </StyledNumberContainer>
      <Heading type='h4' size={'xSmall'} color={'darkPink'} align={'center'}>
        {title}
      </Heading>
      <Text
        color={themeColor === 'blueTheme' ? 'darkestBlue' : 'lightGreen_02'}
        size={'large'}
      >
        {text}
      </Text>
      {stepsNumber < totalSteps && stepsNumber !== totalSteps - 1 && (
        <Circle align={align} themeColor={themeColor} invertColor={invertColor}>
          {image && <Image {...image} altText={image.altText} />}
        </Circle>
      )}
      {stepsNumber === totalSteps - 1 && (
        <Circles
          align={align}
          translateXDisabled
          themeColor={themeColor}
          invertColor={invertColor}
          hasImage={image ? true : false}
        >
          {image && <Image {...image} altText={image.altText} />}
        </Circles>
      )}
    </StyledStep>
  )
}

export { Steps }
