import styled from 'styled-components'
import {
  colors,
  mq,
  spacing,
  typography,
} from '../../styles/utilities/variables'

export const StyledSlimStepContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;

  p {
    margin: unset;
    line-height: 1.4;
    max-width: 800px;
    text-align: center;
    font-size: ${typography.sizes.base};
  }

  h3 {
    width: 100%;
    text-align: center;
  }

  a {
    width: fit-content;
    background-color: ${colors.darkGreen};
  }

  @media ${mq.mobile} {
    align-items: flex-start;
    gap: 1rem;

    h3 {
      text-align: left;
    }

    p {
      text-align: left;
    }
  }
`

export const StyledSlimSteps = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const StyledSlimStep = styled.li`
  background-color: #f0eee7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3.2rem 4.8rem;
  border-radius: 4px;
  gap: 2rem;

  &:nth-child(odd) {
    background-color: #d3dbd5;
  }

  @media ${mq.mobile} {
    padding: 2rem;
    flex-direction: row;
    align-items: start;
    gap: ${spacing.base};
    p {
      font-size: ${typography.sizes.small};
    }
  }
`

export const StyledNumber = styled.span`
  width: 48px;
  font-size: 2.4rem;
  font-weight: 600;
  aspect-ratio: 1;
  border-radius: 50%;
  color: ${colors.white};
  background-color: #ed6763;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${mq.mobile} {
    font-size: 12px;
  }
`
