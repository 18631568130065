/**
 * Parse Yoast SEO
 * @param {*} param
 */
export const parseSeo = ({
  title,
  metaDesc,
  metaKeywords,
  canonical,
  opengraphType,
  opengraphTitle,
  opengraphDescription,
  opengraphImage,
  metaRobotsNoindex,
  metaRobotsNofollow,
}) => {
  return {
    title: title,
    description: metaDesc,
    keywords: metaKeywords,
    keywords: metaKeywords,
    canonical: canonical,
    metaRobotsNoindex: metaRobotsNoindex,
    metaRobotsNofollow: metaRobotsNofollow,
    openGraphType: opengraphType,
    openGraphTitle: opengraphTitle,
    openGraphDescription: opengraphDescription,
    openGraphImage: opengraphImage ? opengraphImage.sourceUrl : null,
  }
}

/**
 * Parse ACF Head
 * @param {*} acfHead
 */
export const parseAcfHead = acfHead => {
  let schema = null
  let hrefLangs = []

  // Schema
  if (acfHead && acfHead.globalPostJsonLd) {
    try {
      schema = JSON.parse(acfHead.globalPostJsonLd)
    } catch {}
  }

  // Href langs
  if (acfHead && acfHead.globalPostHreflangs) {
    hrefLangs = acfHead.globalPostHreflangs
  }

  return {
    schema,
    hrefLangs,
  }
}
