import React from 'react'
import { Wrapper } from '../Wrapper'
import { StyledCheckmarkWithText, StyledWrapper } from './style'

export const CheckmarksWithText = props => {
  const { checkmarks } = props
  return checkmarks ? (
    <StyledWrapper>
      {checkmarks.map(checkmark => (
        <StyledCheckmarkWithText>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 30 30'
          >
            <path
              fill='#ED6763'
              d='M15 30c8.2843 0 15-6.7157 15-15 0-8.28427-6.7157-15-15-15C6.71573 0 0 6.71573 0 15c0 8.2843 6.71573 15 15 15Z'
            />
            <path
              fill='#FFFDFA'
              d='M12.6295 22.6887c.756.5681 1.8294.4159 2.3975-.3401s.4159-1.8294-.3401-2.3975-1.8294-.4159-2.3975.3401-.4159 1.8294.3401 2.3975Zm8.2291-10.9505c.756.5681 1.8294.4159 2.3975-.3401s.4159-1.82939-.3401-2.39751c-.756-.56813-1.8294-.41585-2.3975.34013s-.4159 1.82938.3401 2.39748Zm-4.1154 5.4755c.756.5681 1.8294.4159 2.3975-.3401s.4159-1.8294-.3401-2.3975-1.8294-.4159-2.3975.3401-.4159 1.8294.3401 2.3975Zm-9.59059 1.3606c.75597.5681 1.82937.4159 2.39749-.3401.5681-.756.41584-1.8294-.34013-2.3975-.75598-.5681-1.82937-.4159-2.39749.3401-.56813.756-.41585 1.8294.34013 2.3975Z'
            />
          </svg>
          <span>{checkmark.text}</span>
        </StyledCheckmarkWithText>
      ))}
    </StyledWrapper>
  ) : (
    <></>
  )
}
