import styled from 'styled-components'
import {
  colors,
  spacing,
  typography,
  mq,
} from '../../styles/utilities/variables'
import StyledImage from '../Image/style'
import StyledIcon from '../Icon/style'

const StyledContactCard = styled.li`
  width: 25%;
  padding: ${spacing.base};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${spacing.large};
  @media ${mq.desktop} {
    width: 33%;
  }
  @media ${mq.tablet} {
    width: 50%;
  }
  @media ${mq.mobile} {
    width: 100%;
    margin-bottom: ${spacing.small};
  }
`
StyledContactCard.Name = styled.h5`
    font-size: ${typography.sizes.base};
    font-weight: bold;
`

StyledContactCard.Title = styled.h5`
  font-weight: normal;
  font-size: ${typography.sizes.small};
  color: ${colors.grey};
`

StyledContactCard.Email = styled.a`
  color: ${colors.primary};
  font-size: ${typography.sizes.small};
  text-align: center;
  margin-bottom: ${spacing.small};
  font-weight: 500;
`

StyledContactCard.Image = styled.figure`
  background-color: rgba(0, 0, 0, 0.1);
  height: 130px;
  width: 130px;
  border-radius: 100%;
  position: relative;
  overflow: hidden;
  ${StyledImage} {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    height: 130px;
    width: 130px;
    border-radius: 100%;
    overflow: hidden;
    img,
    picture {
      height: 100%;
      object-fit: cover;
      object-position: 50% 0;
    }
  }
  ${StyledIcon} {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    height: 130px;
    width: 130px;
    i {
      height: 100%;
      color: ${colors.white};
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

StyledContactCard.Number = styled.a`
  color: ${colors.primary};
  font-size: ${typography.sizes.small};
  font-weight: 500;
`

export default StyledContactCard
