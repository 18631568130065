import styled, { css } from 'styled-components'
import { H2 } from '../../styles/base/globalStyles'
import {
  colors,
  spacing,
  mq,
  typography,
} from '../../styles/utilities/variables'
import StyledGrid from '../Grid/style'
import StyledHeading from '../Heading/style'

const colStyle = () => css`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: ${spacing.xLarge};
  position: relative;
  background-color: ${props =>
    props.backgroundColor ? colors[props.backgroundColor] : '#000'};

  h1,
  h2,
  h3,
  ${StyledHeroDuo.PreHeading} {
    color: ${props =>
      props.colorTheme === 'dark' ? colors.dark : colors.white};
    margin-bottom: 0;
  }

  ${StyledHeading.h1} {
    ${H2}
  }
  @media ${mq.mobile} {
    aspect-ratio: 4 / 5;
    max-width: 100%;
  }
`

const StyledHeroDuo = styled.div`
  margin-bottom: ${spacing.large};
  margin-top: -${spacing.xxLarge};

  @media ${mq.tablet} {
    margin-top: -${spacing.xLarge};
  }

  @media ${mq.mobile} {
    margin-top: -${spacing.medium};
    margin-bottom: 0;
  }

  ${StyledGrid} {
    grid-column-gap: 0;
    grid-row-gap: 0;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-rows: minmax(67.2rem, 0);

    @media ${mq.mobile} {
      /* grid-auto-rows: minmax(30rem, 0); */
      grid-auto-rows: auto;
      overflow: hidden;
    }
  }
`

StyledHeroDuo.Overlay = styled.div`
  background: ${props =>
    props.value ? `rgba(0, 0, 0, ${props.value / 100})` : 'rgba(0,0,0,0.3)'};
  position: absolute;
  top: 0;
  z-index: 1;
  left: 0;
  height: 100%;
  width: 100%;
`

StyledHeroDuo.FirstCol = styled.div`
  ${colStyle}
`
StyledHeroDuo.SecondCol = styled.div`
  ${colStyle}
`

StyledHeroDuo.Body = styled.div`
  z-index: 2;
  margin-top: ${props => (props.mediaType === 'video' ? 'auto' : 'initial')};
`

StyledHeroDuo.PreHeading = styled.h4`
  text-transform: uppercase;
  font-size: ${typography.sizes.xSmall};
`

StyledHeroDuo.Image = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`
StyledHeroDuo.Video = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  figure {
    height: 100%;
  }
`

export default StyledHeroDuo
