import styled from 'styled-components'
import { colors } from '../../styles/utilities/variables'

const StyledSplash = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: 1px solid ${colors.primary};
  position: relative;
  /* top: ${props =>
    props.pushUp && !props.alignToBottom
      ? '-114px'
      : props.parentIsVertical
      ? 'unset'
      : '-6%'}; */
  /* bottom: ${props => (props.alignToBottom ? '-110px' : 'unset')}; */
  z-index: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  transform: rotate(10deg);
  color: ${colors.primary};
`

export default StyledSplash
