import styled, { css } from 'styled-components'
import { typography, spacing, mq } from '../../styles/utilities/variables'

const listStyle = () => css`
  font-family: ${props =>
    props.primary
      ? typography.primaryFont
      : props.secondary
      ? typography.secondaryFont
      : props.customFont};
  ${props =>
    props.fancify &&
    props.ordered &&
    css`
      padding-left: 0 !important;
    `}
`

const listItemStyle = () => css`
  height: auto;
  margin-bottom: 20px;

  ${props =>
    !props.fancify &&
    css`
      div:first-of-type {
        float: none;
      }

      span:first-of-type {
        display: block;
      }
    `}

  ${props =>
    props.fancify &&
    props.ordered &&
    css`
      display: flex;

      ::before {
        display: flex;
        color: black;
        margin-right: ${spacing.midBase};

        counter-increment: custom-counter;
        content: counter(custom-counter);
      }

      span:first-of-type {
        max-width: 80%;
      }
    `}
`

const StyledUnorderedList = styled.ul`
  ${listStyle}

  li {
    ${listItemStyle}
  }
`

const StyledOrderedList = styled.ol`
    ${listStyle}
    list-style-type: ${props => (props.fancify ? 'none' : 'decimal')};
    counter-reset: custom-counter;

    li {
        ${listItemStyle}
    }
`

export { StyledOrderedList, StyledUnorderedList }
