import React from 'react'
import { Form } from '../../components/Form'
import { Text } from '../../components/Text'
import { Divider } from '../../components/Divider'
import { Content } from '../../components/Content'
import { SPACING } from '../../helpers/constants'

const FieldsBuilder = props => {
  const { formFields, register, control, errors, dirtyFields } = props

  return formFields.map((field, index) => {
    const { id, label, description } = field

    const inputName = `input_${id}`
    return (
      <Form.Group key={id}>
        <Field
          label={label}
          field={field}
          inputName={inputName}
          register={register}
          control={control}
          inValid={errors[inputName]}
          valid={dirtyFields[inputName] && !errors[inputName]}
        />
        {errors[inputName] && (
          <Form.Error>{errors[inputName].message}</Form.Error>
        )}
        {description && !errors[inputName] && (
          <>
            <Divider size={'xSmall'} />
            <Text size={'xSmall'}>{description}</Text>
          </>
        )}
      </Form.Group>
    )
  })
}

const Field = props => {
  const { field, inputName, register, control, inValid, label, valid } = props
  const {
    type,
    placeholder,
    maxLength,
    isRequired,
    choices,
    checkboxLabel,
    description,
    content,
  } = field

  const options = JSON.parse(choices)

  // console.log(field)

  switch (type) {
    // Start with the standard fields
    case 'text':
    case 'email':
    case 'number':
    case 'hidden':
    case 'phone':
    case 'name':
    case 'address':
      return (
        <Form.Input
          label={label}
          valid={valid}
          type={type}
          placeholder={placeholder}
          name={inputName}
          innerRef={register}
          maxLength={maxLength ? maxLength : undefined}
          inValid={inValid}
          required={isRequired}
          minLength={2}
        />
      )

    case 'textarea':
      return (
        <Form.TextArea
          placeholder={placeholder}
          name={inputName}
          innerRef={register}
          maxLength={maxLength ? maxLength : undefined}
          inValid={inValid}
        />
      )

    case 'select':
      return (
        <Form.Select
          placeholder={placeholder}
          name={inputName}
          options={options.map(option => {
            return { value: option.value, name: option.text }
          })}
          innerRef={register}
          inValid={inValid}
        />
      )

    case 'radio':
      return options.map((option, index) => {
        let optionInputName = `${inputName}.${index + 1}`
        return (
          <React.Fragment key={optionInputName}>
            <Form.Radio
              label={option.text}
              id={optionInputName}
              name={inputName}
              value={option.value}
              innerRef={register}
              inValid={inValid}
            />
            {index < options.length - 1 && <Divider size={SPACING.XX_SMALL} />}
          </React.Fragment>
        )
      })

    case 'checkbox':
      return options.map((option, index) => {
        let optionInputName = `${inputName}_${index + 1}`
        return (
          <React.Fragment key={optionInputName}>
            <Form.Checkbox
              label={option.text}
              id={optionInputName}
              name={optionInputName}
              value={option.value}
              innerRef={register}
              inValid={inValid}
            />
            {index < options.length - 1 && <Divider size={SPACING.XX_SMALL} />}
          </React.Fragment>
        )
      })

    case 'date':
      return (
        <Form.Date
          placeholder={placeholder}
          name={inputName}
          register={register}
          isRequired={isRequired}
          control={control}
          inValid={inValid}
        />
      )
    case 'fileupload':
      return (
        <Form.File
          name={inputName}
          innerRef={register}
          inValid={inValid}
          required={isRequired}
        />
      )

    case 'consent':
      return options.map((option, index) => {
        let optionInputName = `${inputName}_${index + 1}`
        return (
          <React.Fragment key={optionInputName}>
            <Form.Concent
              label={checkboxLabel}
              id={optionInputName}
              name={optionInputName}
              value={option.value}
              innerRef={register}
              inValid={inValid}
            />
            <Divider size={SPACING.X_SMALL} />
            {description && (
              <Content>
                <span dangerouslySetInnerHTML={{ __html: description }} />
              </Content>
            )}
          </React.Fragment>
        )
      })
    case 'html':
      return <div dangerouslySetInnerHTML={{ __html: content }} />

    default:
      return <span>missing</span>
  }
}

export { FieldsBuilder }
