import React from 'react'
import PropTypes from 'prop-types'
import StyledShortcutCard from './style'
import { Button } from '../Button'
import { Content } from '../Content'
import { getUrl } from '../../helpers/url'

const ShortcutCard = ({ item, maxBodyCharLength = 82 }) => {
  let indentString = String.fromCharCode(160)
  let i
  for (i = 0; i < 3; i++) {
    if (i % 2 === 0) indentString += String.fromCharCode(160)
    else indentString += '  –'
  }
  let shortenedItemText =
    indentString + item.text.substring(0, maxBodyCharLength) + '...'

  return (
    <StyledShortcutCard>
      <StyledShortcutCard.Title>{item.title}</StyledShortcutCard.Title>
      <StyledShortcutCard.Body>
        <Content>{item.readMore ? shortenedItemText : item.text}</Content>
        {item.readMore && (
          <Button to={getUrl(item.href)} secondary borderMobile>
            {item.linkText}
          </Button>
        )}
      </StyledShortcutCard.Body>
    </StyledShortcutCard>
  )
}

ShortcutCard.propTypes = {
  item: PropTypes.shape({
    text: PropTypes.string,
    title: PropTypes.string,
    href: PropTypes.string,
    linkText: PropTypes.string,
  }),
  maxBodyCharLength: PropTypes.number,
}
export { ShortcutCard }
