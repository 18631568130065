import React, { useState, useEffect } from 'react'
import StyledVideo from './style'
import { useResponsive } from '../../hooks/useResponsive'
const Video = ({
  src,
  srcMobile = null,
  autoPlay = true,
  loop = true,
  muted = true,
}) => {
  const [videoSrc, setVideoSrc] = useState(null)
  const {
    breakpoints: { mobile },
  } = useResponsive()
  useEffect(() => {
    setVideoSrc(mobile && srcMobile ? srcMobile : src)
  }, [src])
  return (
    <StyledVideo visible={videoSrc}>
      {videoSrc && (
        <video autoPlay={autoPlay} loop={loop} muted={muted} playsInline>
          <source src={videoSrc} type='video/mp4' />
        </video>
      )}
    </StyledVideo>
  )
}

export { Video }
