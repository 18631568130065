import styled, { css } from 'styled-components'
import { spacing, mq } from '../../styles/utilities/variables'

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.col ? 'repeat(' + props.col + ', 1fr)' : '1fr'};
  grid-column-gap: ${props =>
    props.colGap ? spacing[props.colGap] : spacing.large};
  grid-row-gap: ${props =>
    props.rowGap ? spacing[props.rowGap] : spacing.large};
  align-items: ${props => (props.align ? props.align : 'start')};
  justify-items: ${props => (props.justify ? props.justify : 'start')};
  grid-template-areas: ${props =>
    props.gridTemplateAreas ? props.gridTemplateAreas : null};

  .no-cssgrid & {
    display: flex;
    flex-wrap: wrap;
  }

  & > * {
    width: 100%;
    .no-cssgrid & {
      width: ${props =>
        props.col ? `calc(${100 / props.col}% - ${spacing.base})` : 'auto'};
      margin-right: ${spacing.base};
      margin-bottom: ${spacing.base};
    }
  }

  ${props =>
    props.responsive &&
    css`
      @media ${mq.mobile} {
        grid-template-columns: 1fr;
        grid-row-gap: ${spacing.medium};
      }
    `}
`

export default StyledGrid
