import React from 'react'
import PropTypes from 'prop-types'
import StyledIconLinkList from './style'
import { IconListItem } from '../IconListItem'
 
const IconLinkList = ({ items }) => {
    return (
        <StyledIconLinkList>
           {items.map(item => (
               <IconListItem key={item.id} item={item}/>
           ))}
        </StyledIconLinkList>
    )
}
 
IconLinkList.propTypes = {
    items: PropTypes.array
}
export { IconLinkList }