import styled from 'styled-components'
import {
  colors,
  maxWidths,
  mq,
  spacing,
} from '../../styles/utilities/variables'

const StyledTableWrapper = styled.div`
  border: 5px dotted ${colors.red};
  margin: 0 auto;
  width: fit-content;
  padding: ${spacing.medium} ${spacing.xxLarge};

  @media ${mq.mobile} {
    padding: ${spacing.base} 0;
  }
`

export default StyledTableWrapper
