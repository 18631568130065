import styled, { css } from 'styled-components'
import {
  colors,
  spacing,
  typography,
  radius,
  maxWidths,
  cssEasings,
  mq,
} from '../../styles/utilities/variables'
import caretDown from '../../assets/images/caret-down.svg'

const StyledInterestReviews = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.medium};
  margin: 0 auto;
`
StyledInterestReviews.Review = styled.article`
  padding: ${spacing.medium};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.lightestGrey};
  width: 25rem;
  row-gap: ${spacing.small};
  margin-left: ${spacing.small};
  &:first-child {
    margin-left: 0;
  }
  @media ${mq.mobile} {
    margin-left: 0;
    padding: ${spacing.small};
    width: 100%;
  }
`

export default StyledInterestReviews
