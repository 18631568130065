import styled, { css } from 'styled-components'
import { spacing, mq } from '../../styles/utilities/variables'
import StyledHeading from '../Heading/style'
import StyledDivider from '../Divider/style'

const StyledImageCardContentWrapper = styled.div`
  z-index: 1;
  color: white;

  @media ${mq.desktopMin} {
    max-width: 900px;
    margin: 0 auto;
  }

  @media ${mq.tablet} {
    ${StyledDivider} {
      display: none;
    }
  }

  ${props =>
    props.contentAlignment === 'center' &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `}
`

export default StyledImageCardContentWrapper
