import React from 'react'
import PropTypes from 'prop-types'
import StyledQuote from './style'

const Quote = ({ content, author, large, center }) => {
  return (
    <StyledQuote large={large} center={center}>
      <StyledQuote.Content
        dangerouslySetInnerHTML={{ __html: content }}
        large={large}
      />
      {author && (
        <StyledQuote.Author large={large}>{author}</StyledQuote.Author>
      )}
    </StyledQuote>
  )
}

Quote.propTypes = {
  content: PropTypes.any,
  author: PropTypes.string,
  large: PropTypes.bool,
}

export { Quote }
