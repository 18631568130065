import React from 'react'
import PropTypes from 'prop-types'
import StyledAccordionList from './style'
import { Text } from '../Text'
import { AccordionListItem } from '../AccordionListItem'
import { Divider } from '../Divider'
import { Heading } from '../Heading'

const AccordionList = ({
  listTitle,
  listSubtitle,
  listSettings,
  listItems,
}) => {
  return (
    <StyledAccordionList>
      <StyledAccordionList.Preamble>
        <Heading
          type={'h3'}
          color={listSettings.colorTitle}
          size={listTitle.size ? listTitle.size : 'medium'}
        >
          {listTitle.title}
        </Heading>
        {listSubtitle && (
          <>
            <Divider size='xSmall' />
            <Text size={listTitle.size === '_88' ? 'base' : 'midBase'}>
              {listSubtitle}
            </Text>
          </>
        )}
      </StyledAccordionList.Preamble>
      {listSubtitle && <Divider line size={'base'} />}
      {!listSubtitle && <Divider line />}
      <StyledAccordionList.List>
        {listItems &&
          listItems.map((item, key) => (
            <AccordionListItem
              item={item}
              listSettings={listSettings}
              key={key}
            />
          ))}
      </StyledAccordionList.List>
    </StyledAccordionList>
  )
}

AccordionList.propTypes = {
  listTitle: PropTypes.object,
  listSubtitle: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      href: PropTypes.string,
      linkText: PropTypes.string,
    })
  ),
}
export { AccordionList }
