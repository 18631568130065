import styled from 'styled-components'
import StyledDivider from '../Divider/style'
import StyledText from '../Text/style'
import StyledHeading from '../Heading/style'
import { mq, typography, spacing } from '../../styles/utilities/variables'

const StyledAccordionList = styled.div`
  ${StyledDivider} {
    padding-bottom: 0;
  }
  ${StyledText} {
    max-width: 600px;
  }
  ${StyledHeading.h3} {
    font-size: ${typography.sizes._88};
  }
`

StyledAccordionList.List = styled.ul`
  list-style-type: none;
  overflow: hidden;
`

StyledAccordionList.Preamble = styled.div`
  margin-bottom: ${spacing.xLarge};
  ${StyledText} {
    font-size: ${typography.sizes.midBase};
    line-height: 1.5;
    font-weight: 500;
  }
  @media ${mq.mobile} {
    margin-bottom: 0;
    ${StyledText} {
      font-size: ${typography.sizes.small};
    }
    ${StyledHeading.h3} {
      font-size: ${typography.sizes.large};
    }
  }
`

export default StyledAccordionList
