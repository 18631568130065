import styled, { css } from 'styled-components'
import { colors, spacing, radius, mq, boxShadow } from '../../styles/utilities/variables'

const indented = `
  margin:0 auto;
  max-width: 700px;
`
const backgroundImageStyle = () => css`
  margin: -${spacing.xxLarge} 0;
  
  background-image: url('${props => props.backgroundImage}');
  background-repeat: no-repeat;
  background-size: cover;

  @media ${mq.tablet} {
    margin: -${spacing.xLarge} 0 ;
  }

  @media ${mq.mobile} {
    margin: -${spacing.medium} 0;
    padding:0;
    background-image: none;
  }

  & > div {
    background:white;
    box-shadow: ${boxShadow};
    ${indented}
    padding:${spacing.xxLarge};
    border-radius: ${radius.medium};
    margin-bottom: ${spacing.large};
    @media ${mq.tablet} {
      padding:${spacing.xLarge};
    }
    @media ${mq.mobile} {
      padding:${spacing.large} 0;
      border-radius: 0;
      box-shadow: none;
    }
  }
`
const StyledSection = styled.section``

StyledSection.Dark = styled.section`
  background-color: ${colors.lightestGrey};
  padding: ${spacing.xLarge} 0;

  &:not(:first-child) {
    margin-top: ${spacing.xLarge};
  }
  &:not(:last-child) {
    margin-bottom: ${spacing.large};
  }

  &:last-child {
    margin-bottom:-${spacing.xxLarge};
    @media ${mq.tablet} {
      margin-bottom:-${spacing.xLarge};
    }

    @media ${mq.mobile} {
      margin-bottom:-${spacing.medium};
    }
  }

  ${props =>
    props.indented &&
    css`
      & > div {
        ${indented}
      }
    `}
  ${props =>
    props.backgroundImage &&
    css`
      ${backgroundImageStyle()}
    `}
`
StyledSection.Light = styled.section`
  background-color: ${colors.white};
  padding: ${spacing.xLarge} 0;
  ${props =>
    props.indented &&
    css`
      & > div {
        ${indented}
      }
    `}
  ${props =>
    props.backgroundImage &&
    css`
      ${backgroundImageStyle()}
    `}
`

export default StyledSection
