import styled from 'styled-components'
import { mq, spacing, typography } from '../../styles/utilities/variables'

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: ${spacing.medium};

  @media ${mq.mobileSmall} {
    justify-content: space-between;
    margin: -${spacing.medium} 0;
  }
`

export const StyledCheckmarkWithText = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.xSmall};

  span {
    width: fit-content;
    font-size: ${typography.sizes.small};
    font-weight: 500;

    @media ${mq.mobile} {
      font-size: ${typography.sizes._12};
    }
  }

  svg {
    flex-grow: 1;
    width: 30px;
    aspect-ratio: 1;
    @media ${mq.mobile} {
      width: 1.8rem;
    }
  }
`
