import React, { useEffect, useRef } from 'react'
import { StyledTestFreaks } from './style'
import useScript from '../../hooks/useScript'
import { TESTFREAKS_CLIENT_ID } from '../../../node/functions'
import { TestfreaksSchema } from '../TestfreaksSchema'

const Testfreaks = () => {
  const ref = useRef(null)

  const [loading] = useScript({
    src: TESTFREAKS_CLIENT_ID
      ? `https://js.testfreaks.com/onpage/${TESTFREAKS_CLIENT_ID}/head.js`
      : null,
  })

  useEffect(() => {
    if (!loading) {
      let testFreaks = window.testFreaks || []
      testFreaks.push(['setProductId', 'P001'])
      testFreaks.push(['setAggregateRatingSchema', 'json-ld'])
      testFreaks.push(['load', ['badge', 'reviews', 'qa', 'seller', 'videos']])
    }
  }, [loading])

  return (
    <StyledTestFreaks>
      <TestfreaksSchema />
      <div ref={ref} id='testfreaks-seller-reviews'></div>
    </StyledTestFreaks>
  )
}

export { Testfreaks }
