import React from 'react'
import { StyledRevenueList } from './style'
import { Wrapper } from '../Wrapper'
import { Revenue } from '../Revenue'
import { Container } from '../Container'
import { Divider } from '../Divider'
import { Content } from '../Content'
import { Heading } from '../Heading'
import { Text } from '../Text'

export const RevenueList = props => {
  const { title, description, revenues } = props ?? {}
  return (
    <StyledRevenueList>
      <Container>
        <Divider />
        {title || description ? (
          <Content>
            {title ? <Heading color='brightYellow'>{title}</Heading> : null}{' '}
            {description ? (
              <Text color='lightYellow'>{description}</Text>
            ) : null}
            <Divider />
          </Content>
        ) : null}
        <Wrapper flexWrap columnGap='xxxLarge' rowGap='xxLarge'>
          {revenues.map(revenue => (
            <Revenue
              profit={revenue.profit}
              cost={revenue.priceKr}
              text={'i förtjänst på paket som kostar'}
              currency={'kr'}
            />
          ))}
        </Wrapper>
        <Divider />
      </Container>
    </StyledRevenueList>
  )
}
