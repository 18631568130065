import styled from 'styled-components'
import { mq } from '../../styles/utilities/variables'
import StyledRevenue from '../../components/Revenue/style'

const StyledColumn = styled.div`
  align-self: ${props => props.align};
  width: ${props => props.width};

  &:not(:last-child) {
    @media ${mq.mobile} {
      & ${StyledRevenue} {
        margin-bottom: 3.6rem;
      }
    }
  }

  @media ${mq.mobile} {
    width: 100%;
    > * {
      width: 100%;
      height: auto;
      align-items: center;
    }
  }
`

export default StyledColumn
