import styled from 'styled-components'
import { H2 } from '../../../styles/base/globalStyles'
import { colors, typography } from '../../../styles/utilities/variables'

const StyledHeadline2 = styled.h2`
  font-size: ${typography.sizes.medium};
  line-height: 1.5;
  font-weight: bold;
  color: ${props => (props.color ? props.color : colors.black)};
  text-align: ${props => (props.center ? 'center' : 'left')};
`

export default StyledHeadline2
