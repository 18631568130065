import React from "react"
import StyledLoader from "./style"

const Loader = props => {
  return (
    <StyledLoader {...props}>
      <StyledLoader.Bounce1 />
      <StyledLoader.Bounce2 />
    </StyledLoader>
  )
}

Loader.propTypes = {}

export { Loader }
