import styled from 'styled-components'

const StyledEmbed = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &.ratio-1-1 {
    padding-bottom: 100%; /* 16:9 */
  }
  &.ratio-4-3 {
    padding-bottom: 75%; /* 4:3 */
  }
`

export default StyledEmbed
