import styled, { css } from 'styled-components'
import {
  maxWidths,
  typography,
  colors,
  spacing,
  mq,
} from '../../styles/utilities/variables'
import StyledText from '../Text/style'
import StyledHeading from '../Heading/style'
import { motion } from 'framer-motion'

export const StyledSteps = styled.div`
  //background: linear-gradient(180deg, #ffffff 0%, #fffdfa 10%, #ffd6cc 70%);
  background: linear-gradient(0deg, #fffdfa 0%, #ffd6cc 0%, #fffdfa 100%);
  width: 100%;
  margin: 0 auto;
  padding-bottom: 44rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 100%;
  overflow-x: clip;

  @media ${mq.mobile} {
    padding-bottom: 7.2rem;
  }
`

export const StyledStep = styled.div`
  max-width: ${maxWidths.narrowContainer};
  margin: 0 auto;
  //padding-top: 40rem;
  padding-bottom: 4rem;
  position: relative;
  @media ${mq.mobile} {
    ${StyledText} {
      padding: 0 ${spacing.medium};
    }
  }

  ${StyledHeading.h4} {
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: ${spacing.large};
  }
  ${StyledText} {
    font-weight: 500;
    text-align: center;
    line-height: 1.4;
    @media ${mq.mobile} {
      font-size: ${typography.sizes.midBase};
    }
  }
`

export const StyledNumber = styled(motion.div)`
  width: 6.2rem;
  height: 6.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'MatrisePro';
  font-size: ${typography.sizes.large};
  text-align: center;
  color: ${colors.darkPink};
  border: 2px solid ${colors.darkGreen};
  border-radius: 50%;
  margin: 0 auto;
  z-index: 2;
  background-color: #fffdfa;
  //background-color: #ffd6cc;
  position: relative;
  position: sticky;
  top: 150px;

  ${props =>
    props.themeColor === 'blueTheme' &&
    css`
      border-color: ${colors.darkPink};
    `}

  @media ${mq.mobile} {
    width: 5.4rem;
    height: 5.4rem;
    font-size: ${typography.sizes.medium};
  }
`
export const StyledNumberDistance = styled.span`
  background-color: ${colors.darkGreen};
  width: 2px;
  height: 23.9rem;
  position: absolute;
  top: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  ${props =>
    props.themeColor === 'blueTheme' &&
    css`
      background-color: ${colors.darkPink};
    `}
`

export const StyledNumberContainer = styled.div`
  margin-top: ${spacing.xLarge};
  margin-bottom: ${spacing.xxLarge};
  height: 24rem;
  position: relative;
`

export const SmallCircle = styled.div``
