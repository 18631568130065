import React from "react"
import PropTypes from "prop-types"
import StyledPreHeader from "./style"

/**
 * PreHeader
 * @param {*} props
 */
const PreHeader = props => {
  const { children } = props
  return <StyledPreHeader spaceBelow={props.spaceBelow} color={props.color}>{children}</StyledPreHeader>
}
PreHeader.propTypes = {
  children: PropTypes.any,
}

export { PreHeader }
