import React from 'react'
import PropTypes from 'prop-types'
import StyledRevenue from './style'
import { Heading } from '../Heading'
import { Text } from '../Text'
import { RevenueIcon } from './RevenueIcon'
import { Wrapper } from '../Wrapper'

const Revenue = props => {
  const { text, profit, cost, icon, currency, themeColor } = props
  return (
    <StyledRevenue themeColor={themeColor}>
      <RevenueIcon icon={cost} />
      <StyledRevenue.Wrapper>
        <Heading type='h3' align={'center'}>
          {profit} {currency}
        </Heading>
        <Text
          size={'xSmall'}
          color={themeColor === 'blueTheme' ? 'white' : 'lightYellow'}
        >
          {profit} {currency} {text} {cost} {currency}
        </Text>
      </StyledRevenue.Wrapper>
    </StyledRevenue>
  )
}

export { Revenue }

Revenue.propTypes = {
  text: PropTypes.string,
  profit: PropTypes.number,
  packageCost: PropTypes.string,
  icon: PropTypes.string,
}
