import React from 'react'
import { SPACING, FONT_SIZE } from './constants'

import { Heading } from '../components/Heading'
import { Content } from '../components/Content'
import { Container } from '../components/Container'
import { Divider } from '../components/Divider'
import { Text } from '../components/Text'
import { Preamble } from '../components/Text/Preamble'
import { List } from '../components/List'
import { Columns } from '../blocks/Columns'
import { Column } from '../blocks/Column'
import { Section } from '../components/Section'
import { MediaTextShortcut } from '../components/MediaTextShortcut'
import { Button } from '../components/Button'
import { Splash } from '../components/Splash'
import { InteractiveHero } from '../components/InteractiveHero'
import { IconLinkList } from '../components/IconLinkList'
import { ContactCardList } from '../components/ContactCardList'
import { ShortcutCard } from '../components/ShortcutCard'
import { AccordionList } from '../components/AccordionList'
import { Quote } from '../components/Quote'
import { Image } from '../components/Image'
import { Embed } from '../components/Embed'
import { parseVerticalAlign, logger, getIsClient } from './utilities'
import { getFontSize } from './wpBlocksParser'
import { parseContent, stripTags } from './texts'
import { getUrl } from '../helpers/url'
import { Link } from '../components/Link'
import { AlignWrapper } from '../components/AlignWrapper'
import { getString } from './lang'
import Newsletter from '../forms/Newsletter'
import { FormBlock } from '../blocks/FormBlock'
import { MetaTitle } from '../components/Text/MetaTitle'
import { Loader } from '../components/Loader'
import { OnScreenWrapper } from '../components/OnScreenWrapper'
import { AnimatedCounter } from '../components/AnimatedCounter'
import { HeroDuo } from '../components/HeroDuo'
import { Revenue } from '../components/Revenue'
import { TableWrapper } from '../components/TableWrapper'
import { HeroCircular } from '../components/HeroCircular'
import { Steps } from '../components/Steps'
import { TextMedia } from '../components/TextMedia'
import { Calculator } from '../components/Calculator'
import { Testfreaks } from '../components/Testfreaks'
import CustomReviews from '../components/CustomReviews'
import { TestfreaksArchive } from '../components/TestfreaksArchive'
import { Cta } from '../components/Cta'
import { SlimSteps } from '../components/SlimSteps'
import { CheckmarksWithText } from '../components/CheckmarksWithText'
import { RevenueList } from '../components/RevenueList'
import { GamifieraCommunityFeed } from '../blocks/GamifieraCommunityFeed'

const CompanyNews = React.lazy(() => import('../blocks/CompanyNews'))
const Change = React.lazy(() => import('../forms/Change'))
const Interest = React.lazy(() => import('../forms/Interest'))
const Return = React.lazy(() => import('../forms/Return'))
const Latest = React.lazy(() => import('../blocks/Latest'))

/**
 * Block spacing
 */
const BLOCK_SPACING = {
  'core/heading': false,
  'core/paragraph': false,
  'core/list': false,
  'core/column': false,
  'core/separator': false,
  'acf/splash': false,
  'acf/counter': false,
  'acf/testfreaks': false,
  'core/quote': false,
  //'someblock': false // to remove spacing
}

/**
 * WP Blocks Generator
 * @param {*} param
 */
export const WpBlocksGenerator = ({ blocks, post, container = true }) => {
  if (!blocks) return null
  // if (container) logger(blocks)
  return (
    <>
      {blocks.map((block, key) => {
        const spacing =
          BLOCK_SPACING[block.name] !== undefined
            ? BLOCK_SPACING[block.name]
            : SPACING.MEDIUM
        return (
          <React.Fragment key={key}>
            <WpBlockParser block={block} post={post} container={container} />
            {key < blocks.length - 1 && spacing && <Divider size={spacing} />}
          </React.Fragment>
        )
      })}
    </>
  )
}

/**
 * WP Block parser
 * @param {*} param
 */
const WpBlockParser = ({ block, post, container = true }) => {
  // Exclude blocks that dont need container

  const hasContainer = ![
    'acf/section',
    'acf/hero',
    'acf/hero-duo',
    'core/block',
    'acf/steps',
    'acf/text-media',
    'acf/calculator',
    'acf/revenue-list',
  ].includes(block.name)
  if (container && hasContainer)
    return (
      <Container>
        <WpBlock block={block} post={post} />
      </Container>
    )
  return <WpBlock block={block} post={post} />
}

/**
 * WP Block
 * @param {*} param
 */
const WpBlock = ({ block, post }) => {
  const isClient = getIsClient()
  logger(block)

  /**
   * Core Heading
   */
  if (block.name === 'core/heading') {
    const fontSize = block.attributes.fontSize
      ? block.attributes.fontSize
      : block.attributes.level
    let size = getFontSize(fontSize)

    return (
      <Content align={block.attributes.textAlign}>
        <Heading
          bold
          size={size}
          type={`h` + block.attributes.level}
          align={block.attributes.textAlign}
          anchor={block.attributes.anchor}
          color={block.attributes.textColor}
          //light={block.attributes.style != 'strong'}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: parseContent(block.attributes.content),
            }}
          />
        </Heading>
      </Content>
    )
  }

  /**
   * Core Paragraph
   */
  if (block.name === 'core/paragraph') {
    if (!block.attributes.content) return null
    const fontSize = block.attributes.fontSize
    let size = getFontSize(fontSize)
    const style = block.attributes.className

    if (style === 'is-style-magazine-ingress') {
      size = FONT_SIZE.PREABMLE
    }
    let text = null
    const content = parseContent(block.attributes.content)
    if (style === 'is-style-ingress') {
      text = (
        <Preamble
          size={'2.7rem'}
          bold={block.attributes.style === 'strong'}
          center={block.attributes.align === 'center'}
        >
          <span dangerouslySetInnerHTML={{ __html: content }} />
        </Preamble>
      )
    } else if (style === 'is-style-pre-header') {
      text = (
        <MetaTitle
          bold={block.attributes.style === 'strong'}
          center={block.attributes.align === 'center'}
        >
          <span dangerouslySetInnerHTML={{ __html: content }} />
        </MetaTitle>
      )
    } else {
      text = (
        <Text size={size} center={block.attributes.align === 'center'}>
          <span dangerouslySetInnerHTML={{ __html: content }} />
        </Text>
      )
    }
    return <Content align={block.attributes.align}>{text}</Content>
  }

  /**
   * Core Image
   */
  if (block.name === 'core/image') {
    return block.attributes.href ? (
      <Link to={getUrl(block.attributes.href)}>
        <Image {...block.attributes} />
      </Link>
    ) : (
      <Image {...block.attributes} />
    )
  }

  /**
   * Core Columns
   */
  if (block.name === 'core/columns') {
    /**
     * Fix width on blocks
     */
    block.innerBlocks = block.innerBlocks.map(b => {
      if (!b.attributes.width)
        b.attributes.width = parseInt(100 / block.innerBlocks.length)
      return b
    })

    return (
      <Columns cols={block.innerBlocks.length}>
        {block.innerBlocks.map((innerBlock, key) => {
          return <WpBlock key={key} block={innerBlock} post={post} />
        })}
      </Columns>
    )
  }

  /**
   * Core Column
   */
  if (block.name === 'core/column') {
    return (
      <Column
        width={block.attributes.width ? block.attributes.width + '%' : '50%'}
        align={
          block.attributes.verticalAlignment
            ? parseVerticalAlign(block.attributes.verticalAlignment)
            : 'flex-start'
        }
      >
        <WpBlocksGenerator
          blocks={block.innerBlocks}
          container={false}
          post={post}
        />
      </Column>
    )
  }
  /**
   * Core Table
   */
  if (block.name === 'core/table') {
    if (!block.attributes.body.length) return null
    const footRows = block.attributes.foot.length ? block.attributes.foot : null

    const rows = block.attributes.body
    return <TableWrapper rows={rows} foot={footRows} />
  }

  /**
   * ACF Section
   */
  if (block.name === 'acf/section') {
    return (
      <Section
        dark={block.acf.sectionBackgroundColor === 'grey'}
        light={block.acf.sectionBackgroundColor === 'white'}
        indented={block.acf.sectionIndented}
        backgroundImage={block.acf.sectionBackgroundImage}
      >
        <Container>
          <WpBlocksGenerator
            blocks={block.innerBlocks}
            container={false}
            post={post}
          />
        </Container>
      </Section>
    )
  }

  /**
   * Core Media Text
   */
  if (block.name === 'core/media-text') {
    let objectPosition = null
    if (block.attributes.focalPoint) {
      const focalPoint = JSON.parse(block.attributes.focalPoint)
      objectPosition = `${focalPoint['x'] * 100}% ${focalPoint['y'] * 100}%`
    }

    return (
      <MediaTextShortcut
        mediaPosition={block.attributes.mediaPosition}
        imageSrc={block.attributes.mediaUrl}
        image={block.attributes}
        objectPosition={objectPosition}
        alt={block.attributes.mediaAlt}
        to={block.attributes.href ? getUrl(block.attributes.href) : null}
        vertical={block.attributes.className ? 'is-style-vertical-image' : null}
      >
        <WpBlocksGenerator
          blocks={block.innerBlocks}
          container={false}
          post={post}
        />
      </MediaTextShortcut>
    )
  }

  /**
   * Media & Text Rounded
   */
  if (block.name === 'acf/text-media') {
    let random
    if (block.acf.textMediaImages) {
      const images = block.acf.textMediaImages
      random = images[Math.floor(Math.random() * images.length)]
      random = random.textMediaImage
    }
    return (
      <TextMedia
        layout={block.acf.textMediaLayoutChoice}
        title={block.acf.textMediaTitle}
        subtitle={block.acf.textMediaSubtitle}
        titleSize={block.acf.textMediaTitleSize}
        text={block.acf.textMediaText}
        mirrored={block.acf.textMediaMirrored}
        image={block.acf.textMediaImage}
        randomImage={random}
        link={block.acf.textMediaLink}
        theme={block.acf.textMediaTheme}
        overflow={block.acf.textMediaLayoutOverflow}
      />
    )
  }

  /**
   * Core Buttons
   */
  if (block.name === 'core/buttons') {
    return block.innerBlocks.map((innerBlock, key) => {
      const style = block.innerBlocks[0].attributes.className
      let theme = null
      if (style === 'is-style-pink-theme') {
        theme = 'pinkTheme'
      } else if (style === 'is-style-green-theme') {
        theme = 'greenTheme'
      } else if (style === 'is-style-green-theme-v2') {
        theme = 'greenThemeV2'
      } else if (style === 'is-style-blue-theme') {
        theme = 'blueTheme'
      }

      return (
        <AlignWrapper align={block.attributes.align}>
          <Button
            primary={innerBlock.attributes.className === 'is-style-fill'}
            secondary={innerBlock.attributes.className === 'is-style-outline'}
            to={getUrl(innerBlock.attributes.url)}
            align={block.attributes.align}
            themeColor={theme}
          >
            {innerBlock.attributes.text}
          </Button>
        </AlignWrapper>
      )
    })
  }

  /**
   * ACF Splash
   */
  if (block.name === 'acf/splash') {
    return (
      <Splash pushUp parentIsVertical>
        <span dangerouslySetInnerHTML={{ __html: block.acf.splashText }} />
      </Splash>
    )
  }

  /**
   * ACF Hero
   */
  if (block.name === 'acf/hero') {
    const headingSize = block.acf.heroIngress
      ? FONT_SIZE.XXX_LARGE
      : FONT_SIZE.XX_LARGE

    if (block.acf.heroLayout === 'shapes')
      return (
        <HeroCircular
          image={block.acf.heroBgImage}
          mediaType={block.acf.heroMediaType}
          video={block.acf.heroVideo}
          titleSettings={block.acf.heroTitleSettings}
          theme={block.acf.heroTheme}
          ingress={block.acf.heroIngress}
          buttonCaption={
            block.acf.heroPrimaryButton
              ? block.acf.heroPrimaryButton.title
              : null
          }
          buttonHref={
            block.acf.heroPrimaryButton
              ? getUrl(block.acf.heroPrimaryButton.url)
              : null
          }
        />
      )
    else
      return (
        <InteractiveHero
          overlay={block.acf.heroOverlay}
          heroHeight={'64.5rem'}
          theme={block.acf.heroTheme}
          mediaType={block.acf.heroMediaType}
          image={block.acf.heroBgImage}
          video={block.acf.heroVideo}
          videoMobile={block.acf.heroVideoMobile}
          contentAlignment={'center'}
          buttonCaption={
            block.acf.heroPrimaryButton
              ? block.acf.heroPrimaryButton.title
              : null
          }
          buttonHref={
            block.acf.heroPrimaryButton ? block.acf.heroPrimaryButton.url : null
          }
          secondButtonCaption={
            block.acf.heroSecondaryButton
              ? block.acf.heroSecondaryButton.title
              : null
          }
          secondButtonHref={
            block.acf.heroSecondaryButton
              ? block.acf.heroSecondaryButton.url
              : null
          }
        >
          <Container>
            {block.acf.heroTitleSettings &&
              block.acf.heroTitleSettings.heroTitle && (
                <Heading type={'h1'} size={headingSize} align={'center'}>
                  {block.acf.heroTitleSettings.heroTitle}
                </Heading>
              )}
            {block.acf.heroIngress && (
              <>
                <Divider />
                <Text center size={FONT_SIZE.LARGE}>
                  {block.acf.heroIngress}
                </Text>
              </>
            )}
          </Container>
        </InteractiveHero>
      )
  }

  /**
   * ACF Icon
   */
  if (block.name === 'acf/icon') {
    const items = [
      {
        icon: block.acf.iconName,
        href: block.acf.iconLink ? getUrl(block.acf.iconLink.url) : null,
        title: block.acf.iconTitle,
        preamble: block.acf.iconText,
      },
    ]

    return <IconLinkList items={items} />
  }

  /**
   * ACF Staff
   */
  if (block.name === 'acf/staff') {
    if (!block.acf.staff || !block.acf.staff.length) return null
    const showTitle = block.acf.staffShowTitles
    const contacts = block.acf.staff
      .filter(staff => staff)
      .map((staff, key) => {
        const featuredImage = staff.featuredImage
          ? staff.featuredImage.node
          : null
        return {
          id: key,
          showTitle: showTitle,
          image: featuredImage,
          name: staff.title,
          title: staff.acf.titel,
          number: staff.acf.staffPhone,
          email: staff.acf.staffEmail,
        }
      })

    return <ContactCardList contacts={contacts} />
  }

  /**
   * Core Separator
   */
  if (block.name === 'core/separator') {
    const line = block.attributes.className === 'is-style-border'
    return <Divider line={line} className={!line ? 'spacer' : ''} />
  }

  /**
   * ACF Latest
   */
  if (block.name === 'acf/latest') {
    return (
      <>
        {isClient && (
          <React.Suspense fallback={<Loader />}>
            <Latest limit={block.acf.latestNewsNumber} />
          </React.Suspense>
        )}
      </>
    )
  }

  /**
   * ACF FAQ Shortcut
   */
  if (block.name === 'acf/faq-shortcut') {
    if (!block.acf.faqShortcutQuestion || !block.acf.faqShortcutQuestion.length)
      return null

    const question = block.acf.faqShortcutQuestion[0]
    const text = question.excerpt
      ? stripTags(question.excerpt)
      : stripTags(question.content)
    const item = {
      title: question.title,
      text: text,
      href: question.uri,
      linkText: getString('FAQ_READ_MORE'),
      readMore: question.acf.faqReadMoreLink,
    }
    return <ShortcutCard maxBodyCharLength={100} item={item} />
  }

  /**
   * ACF FAQ Accordion
   */
  if (block.name === 'acf/faq-accordion') {
    if (!block.acf.faqAccordionPost || !block.acf.faqAccordionPost.length)
      return null

    const listItems = block.acf.faqAccordionPost.map((post, key) => {
      return {
        id: key,
        title: post.title,
        href: post.uri,
        linkText: getString('FAQ_READ_MORE'),
        text: post.excerpt ? stripTags(post.excerpt) : stripTags(post.content),
        readMore: post.acf.faqReadMoreLink,
        textEarnMoney: post.acf.faqTextForEarnMoneyPage,
      }
    })
    return (
      <AccordionList
        listTitle={block.acf.faqAccordionTitle}
        listSubtitle={block.acf.faqAccordionSubtitle}
        listSettings={block.acf.faqAccordionSettings}
        listItems={listItems}
      />
    )
  }

  /**
   * Core Quote
   */
  if (block.name === 'core/quote') {
    return (
      <Quote
        author={block.attributes.citation}
        content={block.attributes.value}
        large={block.attributes.className === 'is-style-large'}
        center={block.attributes.align === 'center'}
      />
    )
  }

  /**
   * ACF Form Interest
   */
  if (block.name === 'acf/form-interest') {
    return (
      <>
        {isClient && (
          <React.Suspense fallback={<Loader />}>
            <Interest
              title={block.acf.interestFormTitle}
              preamble={block.acf.interestFormPreamble}
              type={block.acf.interestFormType}
              brand={block.acf.interestFormBrand}
              brandVisible={block.acf.interestFormBrandVisible}
              sentText={block.acf.interestFormSentText}
              updatedText={block.acf.interestFormUpdatedText}
            />
          </React.Suspense>
        )}
      </>
    )
  }

  /**
   * ACF Form Change
   */
  if (block.name === 'acf/form-change') {
    return (
      <>
        {isClient && (
          <React.Suspense fallback={<Loader />}>
            <Change />
          </React.Suspense>
        )}
      </>
    )
  }

  /**
   * ACF Form Return
   */
  if (block.name === 'acf/form-return') {
    return (
      <>
        {isClient && (
          <React.Suspense fallback={<Loader />}>
            <Return />
          </React.Suspense>
        )}
      </>
    )
  }

  /**
   * ACF Form Newsletter
   */
  if (block.name === 'acf/form-newsletter') {
    return (
      <>
        {isClient && (
          <React.Suspense fallback={<Loader />}>
            <Newsletter />
          </React.Suspense>
        )}
      </>
    )
  }

  /**
   * ACF Custom Reviews
   */
  if (block.name === 'acf/custom-reviews') {
    return <CustomReviews reviews={block.acf.customReviewsContainer} />
  }

  /**
   * Core Embed
   */
  if (block.name === 'core/embed') {
    return <Embed>{block.attributes.oEmbed}</Embed>
  }

  /**
   * Core List
   */
  if (block.name === 'core/list') {
    let listItems
    const fancyNumbers = block.attributes.className === 'is-style-fancy-numbers'
    if (fancyNumbers) {
      listItems = block.attributes.values.split('<li>').join('<li><div>')
      listItems = listItems.split('</li>').join('</div></li>')
    } else {
      listItems = block.attributes.values
    }

    const listProps = {
      dangerouslySetInnerHTML: { __html: parseContent(listItems) },
    }

    return (
      <Content>
        <List
          ordered={block.attributes.ordered}
          fancify={block.attributes.className === 'is-style-fancy-numbers'}
        >
          <div {...listProps} />
        </List>
      </Content>
    )
  }

  if (block.name === 'acf/form') {
    const { formId } = block.acf
    return <FormBlock formId={formId} />
  }

  if (block.name === 'acf/hero-duo') {
    const { leftSide, rightSide } = block.acf
    return (
      <Container wide noPadding>
        <HeroDuo
          firstHeading={leftSide.heading}
          firstPreHeading={leftSide.preheading}
          firstMediaType={leftSide.mediaType}
          firstVideo={leftSide.video}
          firstImage={leftSide.image}
          firstLinkUrl={leftSide.button.url}
          firstLinkTitle={leftSide.button.title}
          firstLinkTargetBlank={leftSide.button.target}
          firstButtonColor={leftSide.buttonColor}
          firstOverlay={leftSide.darkoverlay}
          secondHeading={rightSide.heading}
          secondPreHeading={rightSide.preheading}
          secondMediaType={rightSide.mediaType}
          secondVideo={rightSide.video}
          secondImage={rightSide.image}
          secondLinkUrl={rightSide.button.url}
          secondLinkTitle={rightSide.button.title}
          secondLinkTargetBlank={rightSide.button.target}
          secondButtonColor={rightSide.buttonColor}
          secondOverlay={rightSide.darkoverlay}

          /*
          * I did not add these props
          **
          firstBackgroundColor={}
          firstColorTheme={}
          firstOverlayValue={}
          secondBackgroundColor={}
          secondColorTheme={}
          secondOverlayValue={} */
        />
      </Container>
    )
  }

  /**
   * ACF Counter
   */
  if (block.name === 'acf/counter') {
    return (
      <OnScreenWrapper>
        <AnimatedCounter
          targetNumber={block.acf.counterNumber}
          unit={block.acf.counterUnit}
        />
      </OnScreenWrapper>
    )
  }
  /**
   *  ACF Revenue
   */
  if (block.name === 'acf/revenue') {
    return (
      <Revenue
        text={block.acf.revenueText}
        earning={block.acf.revenueEarning}
        packageCost={block.acf.revenuePackagePrice}
        icon={block.acf.revenueIcon}
      />
    )
  }
  /**
   *  ACF Steps
   */
  if (block.name === 'acf/steps') {
    return <Steps steps={block.acf.steps} title={block.acf.stepsTitle} />
  }

  /**
   * Calculator
   */
  if (block.name === 'acf/calculator') {
    return (
      <Calculator
        title={block.acf.calculatorTitle}
        text={block.acf.calculatorText}
        currency={block.acf.calculatorCurrency}
        revenueText={block.acf.calculatorRevenueText}
        revenues={block.acf.calculatorRevenue}
        themeColor={block.acf.calculatorThemeColor}
      />
    )
  }

  /**
   * ACF Company News
   */
  if (block.name === 'acf/company-news') {
    return (
      <>
        {isClient && (
          <React.Suspense fallback={<Loader />}>
            <CompanyNews limit={block.acf.companyNewsNumber} />
          </React.Suspense>
        )}
      </>
    )
  }

  /**
   * ACF Testfreaks
   */
  if (block.name === 'acf/testfreaks') {
    return <Testfreaks />
  }

  /**
   * ACF Testfreaks Archive
   */
  if (block.name === 'acf/testfreaks-archive') {
    return <TestfreaksArchive />
  }

  if (block.name === 'acf/cta') {
    const {
      ctaDescription,
      ctaImage,
      ctaLink,
      ctaTitle,
      ctaTitleColor,
      ctaMirrored,
    } = block.acf ?? {}

    return (
      <Cta
        description={ctaDescription}
        image={ctaImage}
        link={ctaLink}
        title={ctaTitle}
        titleColor={ctaTitleColor}
        mirrored={ctaMirrored}
      />
    )
  }

  if (block.name === 'acf/slim-steps') {
    const { steps } = block.acf ?? {}
    return <SlimSteps steps={steps} />
  }

  if (block.name === 'acf/checkmarks-with-text') {
    const { checkmarks } = block.acf ?? {}
    return <CheckmarksWithText checkmarks={checkmarks} />
  }

  if (block.name === 'acf/revenue-list') {
    console.log('revenue-list', block)
    const { revenueListTitle, revenueListDescription, revenueListRevenues } =
      block.acf ?? {}
    return (
      <RevenueList
        title={revenueListTitle}
        description={revenueListDescription}
        revenues={revenueListRevenues}
      />
    )
  }

  if (block.name === 'acf/community-feed') {
    return <GamifieraCommunityFeed />
  }

  /**
   * Core Block - Reusable
   */
  if (block.name === 'core/block') {
    return (
      <WpBlocksGenerator
        blocks={block.reusableBlock.blocks}
        container={false}
      />
    )
  }

  /**
   * Core Block - HTML
   */
  if (block.name === 'core/html') {
    return <div dangerouslySetInnerHTML={{ __html: block.originalContent }} />
  }

  return null
}
