import { useStaticQuery, graphql } from 'gatsby'

export const useFormQuery = formId => {
  const staticQuery = useStaticQuery(graphql`
    {
      allGfForm {
        nodes {
          formId
          title
          description
          slug
          apiURL
          descriptionPlacement
          formFields {
            id
            label
            description
            descriptionPlacement
            type
            choices
            errorMessage
            inputMaskValue
            isRequired
            visibility
            cssClass
            placeholder
            size
            defaultValue
            maxLength
            conditionalLogic
            emailConfirmEnabled
            content
            inputs {
              id
              label
              name
              isHidden
            }
            checkboxLabel
            descriptionPlaceholder
            isRequired
          }
          button {
            text
          }
          confirmations {
            type
            message
            url
          }
        }
      }
    }
  `)

  const formData = staticQuery.allGfForm.nodes.filter(
    node => node.formId === parseInt(formId)
  )[0]

  return formData
}
