import { useStaticQuery, graphql } from "gatsby"

export const useFormSettings = () => {
    const data = useStaticQuery(graphql`
    query interestFormQuery {
      wordpress {
        formSettings {
          data {
            formConsentText
          }
        }
      }
    }
  `)
  return data.wordpress.formSettings.data
}

