import styled, { css } from 'styled-components'
import {
  spacing,
  mq,
  colors,
  maxWidths,
  typography
} from '../../styles/utilities/variables'
import StyledText from '../Text/style'

const StyledContent = styled.div`
  line-height: 1.5;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: ${spacing.medium};
    max-width: ${maxWidths.headings};
    @media ${mq.mobile} {
      margin-bottom: ${spacing.small};
    }
  }

  p,
  ul,
  ol {
    margin-bottom: ${spacing.medium};
    line-height: 1.5;
    max-width: ${maxWidths.base};
    @media ${mq.mobile} {
      margin-bottom: ${spacing.small};
    }
    &:last-child {
      //margin-bottom: 0;
    }
  }

  ul,
  ol {
    padding-left: ${spacing.large};
    li {
      margin-bottom: ${spacing.medium};
    }
    @media ${mq.mobile} {
      padding-left: ${spacing.large};
    }
  }

  em {
    font-style: italic;
  }

  a {
    color: ${colors.primary};
  }

  ${props =>
    props.align === 'center' &&
    css`
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      ol,
      ul {
        margin-left: auto;
        margin-right: auto;
      }
    `}
  ${props =>
    props.align === 'right' &&
    css`
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      ol,
      ul {
        margin-left: auto;
        margin-right: 0;
        text-align:right;
      }
    `}
`

export default StyledContent
