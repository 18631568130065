import React from 'react'
import PropTypes from 'prop-types'
import { StyledOrderedList, StyledUnorderedList } from './style'
 
const List = ({
    children, 
    primary, 
    secondary, 
    customFont = 'inherit',
    ordered,
    fancify,
}) => {

    const ListComponent = ordered ? StyledOrderedList : StyledUnorderedList;

    return (
        <ListComponent ordered={ordered} primary={primary} secondary={secondary} customFont={customFont} fancify={fancify}>
            {children}
        </ListComponent>
    )
}
 
List.propTypes = {
    children: PropTypes.any,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    customFont: PropTypes.string,
    ordered: PropTypes.bool,
    fancify: PropTypes.bool,
}
export { List }
