import React, { useEffect, useState } from 'react'
import { Wrapper } from '../Wrapper'
import { Heading } from '../Heading'
import { Text } from '../Text'
import StyledInterestReviews from './style'
import { TESTFREAKS_CLIENT_ID } from '../../../node/functions'
import { getString } from '../../helpers/lang'

const GetStarRating = rating => {
  const maxStars = 5
  let stars = []
  for (let i = 0; i < maxStars; i++) {
    if (i < rating) {
      stars.push(1)
    } else {
      stars.push(0)
    }
  }
  return (
    <Wrapper align={'center'}>
      {stars.length > 0 &&
        stars.map(star => {
          return (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              width='17'
              height='15'
              viewBox='0 0 17 15'
            >
              <path
                fill={star === 1 ? '#ffa534' : '#C8C7C7'}
                d='m8.5 0 1.796 5.528h5.813l-4.703 3.416 1.796 5.528L8.5 11.056l-4.702 3.416 1.796-5.528L.892 5.528h5.812L8.5 0Z'
              />
            </svg>
          )
        })}
    </Wrapper>
  )
}
const CustomReviews = ({ title, reviews }) => {
  const [totalReviews, setTotalReviews] = useState([])

  useEffect(() => {
    // "newbodyfamily.com-admins" has data from the testfreaks API,
    // needs to be different from main client
    const apiUrl = `https://cr.testfreaks.com/seller_reviews.json?client_id=newbodyfamily.com-admins`
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        const reviews = data.review_count
        setTotalReviews(reviews)
      })
      .catch(error => console.error('Error fetching review data:', error))
  }, [])

  return (
    <StyledInterestReviews>
      <Heading type='h3' size='small' align='center'>
        {`${getString('CUSTOM_REVIEWS_START')}${totalReviews}${getString(
          'CUSTOM_REVIEWS_END'
        )}`}
      </Heading>
      <Wrapper align={'stretch'} justify={'space-between'} responsive>
        {reviews &&
          reviews.map((review, key) => {
            return (
              <StyledInterestReviews.Review key={key}>
                {GetStarRating(review.rating)}
                <Heading type={'h4'} size={'small'} align={'center'}>
                  {review.title}
                </Heading>
                <Text size={'small'} center>
                  {review.author}
                </Text>
              </StyledInterestReviews.Review>
            )
          })}
      </Wrapper>
    </StyledInterestReviews>
  )
}
export default CustomReviews
