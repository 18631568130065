import React from 'react'
import StyledAlignWrapper from './style';

/**
 * AlignWrapper
 * @param {*} param
 */
const AlignWrapper = ({ children, align }) => {
    return (
        <StyledAlignWrapper align={align}>
            {children}
        </StyledAlignWrapper>
    )
}

export { AlignWrapper }