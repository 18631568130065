import React from 'react'
import StyledColumn from './style'

const Column = ({ width, align, children }) => {
  return (
    <StyledColumn width={width} align={align}>
      {children}
    </StyledColumn>
  )
}
export { Column }
