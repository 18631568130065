import styled, { css } from 'styled-components'
import { mq, spacing, colors } from '../../styles/utilities/variables'
import StyledColumn from '../Column/style'
import StyledMediaTextShortcut from '../../components/MediaTextShortcut/style'

const StyledColumns = styled.div`
  display: flex;
  justify-content: flex-start;

  @media ${mq.mobile} {
    flex-direction: column;
  }

  & > * {
    margin-right: ${spacing.xLarge};

    @media ${mq.desktop} {
      margin-right: ${spacing.large};
    }

    @media ${mq.tablet} {
      margin-right: ${spacing.medium};
    }

    ${StyledColumn} {
      margin-right: ${spacing.large};
    }

    & ${StyledMediaTextShortcut.Wrapper} {
      margin-right: -${spacing.xLarge};

      @media ${mq.desktop} {
        margin-right: -${spacing.large};
      }
      @media ${mq.tablet} {
        margin-right: -${spacing.medium};
      }

      @media ${mq.mobile} {
        margin-right: 0;
        margin-bottom: ${spacing.xLarge};
      }
    }

    @media ${mq.mobile} {
      margin-right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: ${spacing.small};

      &:last-child {
        margin-bottom: 0;
      }

      ${StyledColumn} {
        margin-right: 0;
      }
    }

    &:not(${StyledColumn}):last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  @media ${mq.desktopMin} {
    ${props =>
      props.cols === 2 &&
      css`
      ${StyledColumn} {
        :first-of-type {
          /* border-right: 1px solid ${colors.borderColor}; */
          /* padding-right: 2.5%; */
          /* margin: 0; */
        }
        :last-of-type {
          /* padding-left: 2.5%}; */
        }
    `}
  }
`

export default StyledColumns
