import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { sendForm } from '../../helpers/api'
import { handleGravityFormsValidationErrors } from '../../helpers/form'
import { useFormQuery } from './hook'
import StyledFormBlock from './style'
import { Button } from '../../components/Button'
import { COLORS, FONT_SIZE, SPACING } from '../../helpers/constants'
import { Divider } from '../../components/Divider'
import { Content } from '../../components/Content'
import { Form } from '../../components/Form'
import { FieldsBuilder } from './FieldsBuilder'
import { Loader } from '../../components/Loader'
import { Icon } from '../../components/Icon'
import { Error } from '../../forms/Messages'
import { CircledHeart } from '../../components/Svg/svg'
import { Wrapper } from '../../components/Wrapper'

const FormBlock = props => {
  const { formId } = props

  const [generalError, setGeneralError] = useState('')
  const [formLoading, setFormLoading] = useState(false)
  const [sent, setSent] = useState(false)
  const [confirmationMessage, setConfirmationMessage] = useState('')
  const formData = useFormQuery(formId)

  const {
    apiURL,
    formFields,
    button: { text: submitText },
  } = formData

  const initialFieldValues = formFields.reduce((acc, field) => {
    acc[`input_${field.id}`] = ''
    return acc
  }, {})

  const {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    // setValue,
    // triggerValidation,
    formState: { dirtyFields },
  } = useForm({ mode: 'onBlur', defaultValues: initialFieldValues })

  if (!formData) return null
  /**
   * On submit
   * @param {*} values
   */
  const onSubmit = values => {
    if (!formLoading) {
      setFormLoading(true)
      setGeneralError(false)
      sendForm(apiURL, values)
        .then(function(data) {
          if (data.status === 'success') {
            /**
             * Redirect?
             */
            if (data.data.confirmation_redirect) {
              window.location = data.data.confirmation_redirect
              return
            }
            /**
             * Message?
             */
            if (data.confirmation_message) {
              setConfirmationMessage(data.confirmation_message)
            }
            // Sent sent...
            setSent(true)
          } else if (data.status === 'gravityFormErrors') {
            // Pass messages to handle that sets react-hook-form errors
            handleGravityFormsValidationErrors(
              data.validation_messages,
              setError
            )
          } else {
            // Seemed to be an unknown issue
            setGeneralError(true)
          }
        })
        .catch(e => {
          setGeneralError(true)
        })
        .finally(() => {
          setFormLoading(false)
        })
    }
  }
  return (
    <StyledFormBlock>
      {!sent && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Wrapper flexWrap={'wrap'} columnGap={'small'} justify={'center'}>
            <FieldsBuilder
              dirtyFields={dirtyFields}
              formFields={formFields}
              register={register}
              control={control}
              errors={errors}
            />
            <Form.Group>
              <StyledFormBlock.Footer>
                {formLoading && <Loader />}
                {!formLoading && (
                  <Button large textColor={COLORS.SECONDARY_LIGHT_BLUE}>
                    {submitText}
                  </Button>
                )}
              </StyledFormBlock.Footer>
            </Form.Group>
            {generalError && <Error />}
          </Wrapper>
        </Form>
      )}

      {sent && (
        <Content>
          {confirmationMessage && (
            <>
              <Divider size={SPACING.SMALL} />
              <Wrapper align={'center'} direction={'column'} justify={'center'}>
                <CircledHeart />
                <StyledFormBlock.Confirmation>
                  <Icon icon={'check_circle_outline'} />
                  <StyledFormBlock.ConfirmationInner
                    dangerouslySetInnerHTML={{ __html: confirmationMessage }}
                  />
                </StyledFormBlock.Confirmation>
              </Wrapper>
            </>
          )}
        </Content>
      )}
    </StyledFormBlock>
  )
}

FormBlock.propTypes = {
  formId: PropTypes.string,
}

export { FormBlock }
