import styled, { css } from 'styled-components'
import {
  spacing,
  maxWidths,
  mq,
  colors,
  typography,
} from '../../styles/utilities/variables'
import {
  StyledCircle,
  StyledCircleContainer,
  StyledSmallCircle,
} from '../Circle/style'
import StyledHeading from '../Heading/style'
import StyledButton from '../Button/style'
import StyledText from '../Text/style'
import StyledImage from '../Image/style'

export const StyledTextMedia = styled.section`
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
`

export const StyledTextMediaContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: ${props => (props.layout === 'regular' ? 0 : spacing.medium)};
  max-width: ${maxWidths.wideContainer};
  margin: 0 auto;
  @media (min-width: 1440px) {
    overflow-x: unset;
  }
  @media ${mq.tablet} {
    row-gap:  ${spacing.medium};
  }
  ${props =>
    props.layout === 'regular' &&
    css`
      ${StyledImage} {
        grid-column: 7/13;
        grid-row: 1;
        transform: translateX(150px);
        align-self: center;
        @media ${mq.tablet} {
          /* grid-column: 5/13; */
          grid-column: 6/13;
          transform: translateX(100px);
        }
        @media ${mq.mobile} {
          grid-column: 2/13;
          transform: translateX(100px);
        }
      }
    `}

  ${StyledCircle} {
    grid-column: 6/13;
    grid-row: 1;
   transform: translateX(450px);
    @media ${mq.tablet} {
      grid-column: 7/13;
      width: 100%;
      min-width: 28.6rem;
      transform: translateX(100px);
      align-self: flex-end;
    }
  }
  ${StyledSmallCircle} {
    grid-column: 8/13;
    grid-row: 1;
    align-self: flex-end;

    @media ${mq.tablet} {
      grid-column: 7/13;
    }
    @media ${mq.mobile} {
     grid-column: 9/13;
     max-width: 18.6rem;
    }
  }

  ${props =>
    props.mirrored &&
    css`
      ${StyledTextMediaContent} {
        grid-column: 7/12;
        @media ${mq.tablet} {
          grid-column: 1/12;
          padding: 0 ${spacing.medium};
        }
      }
      ${props =>
        props.layout === 'regular' &&
        css`
          ${StyledImage} {
            //grid-column: 1/7;
            grid-column: 1/9;
            transform: translateX(-250px);
            @media ${mq.tablet} {
              grid-column: 1/8;
              grid-column: 1/10;
              transform: translateX(-200px);
            }
            @media ${mq.mobile} {
              grid-column: 1/13;
              transform: translateX(-100px);
            }
          }
        `}

      ${StyledCircle} {
        grid-column: 1/9;
        transform: translateX(-300px);
        @media ${mq.tablet} {
          grid-column: 1/7;
          transform: translateX(-100px);
          align-self: flex-end;
        }
      }
      ${StyledSmallCircle} {
        grid-column: 3/7;
        @media ${mq.tablet} {
          grid-column: 3/13;
        }
        @media ${mq.mobile} {
          grid-column: 1/7;
        }
      }
    `}

  /** A'la special styling for when component is last of page  */
   ${props =>
     props.overflow &&
     css`
       overflow-y: clip;
       @media ${mq.tablet} {
         row-gap: 0;
       }
       @media ${mq.mobile} {
         max-height: 45rem;
       }
       ${StyledCircle} {
         //transform: translateX(380px) translateY(7rem);
         @media ${mq.tablet} {
           //transform: translateX(100px) translateY(0rem);
           align-self: flex-end;
           grid-row: 2;
         }
       }
       ${StyledSmallCircle} {
         //transform: translateX(1rem) translateY(8rem);
         @media ${mq.tablet} {
           grid-column: 7/13;
           grid-row: 2;ß
         }
       }
       ${StyledTextMediaContent} {
         @media ${mq.tablet} {
           grid-row: 1;
         }
       }
     `}

  /** A'la special styling for when component is last of page and mirrored */
   ${props =>
     props.overflow &&
     props.mirrored &&
     css`
       overflow-y: clip;
       @media ${mq.tablet} {
         row-gap: 0;
       }
       ${StyledCircle} {
         @media ${mq.tablet} {
           grid-column: 7/13;

           align-self: flex-end;
           grid-row: 2;
         }
       }
       ${StyledSmallCircle} {
         @media ${mq.tablet} {
           grid-column: 7/13;
           grid-row: 2;
         }
       }
       ${StyledTextMediaContent} {
         @media ${mq.tablet} {
           grid-row: 1;
         }
       }
     `}
`

export const StyledTextMediaContent = styled.div`
  grid-column: 2/7;
  grid-row: 1;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-self: center;
  ${StyledHeading.h3} {
    color: ${props =>
      props.theme === 'greenTheme'
        ? colors.lightGreen_02
        : props.theme === 'blueTheme'
        ? colors.darkestBlue
        : colors.darkPink};
    margin-bottom: ${spacing.base};
    @media ${mq.mobile} {
      font-size: ${props =>
        props.size === '_88'
          ? typography.sizes.large
          : typography.sizes.medium};
    }
  }
  ${StyledText} {
    line-height: 1.5;
    font-weight: 500;
    @media ${mq.mobile} {
      font-size: 1.5rem;
    }
  }
  ${StyledButton.a} {
    width: fit-content;
    margin-top: ${spacing.medium};
  }

  @media ${mq.tablet} {
    grid-row: 2;
    grid-column: 1/12;
    padding: 0 ${spacing.medium};
  }
`
