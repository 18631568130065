import styled from 'styled-components'
import { typography, colors, mq } from '../../styles/utilities/variables'
import { Link } from '../Link'
import StyledHeadline2 from '../Text/Headline2/style'
import StyledContent from '../../components/Content/style'

const StyledIconListItem = styled.li`
  max-width: 38rem;
  width: 100%;
  float: left;
  padding: 4%;

  ${StyledContent} {
    width: 100%;
  }
`

StyledIconListItem.Link = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  ${StyledHeadline2} {
    font-family: ${typography.primaryFont};
  }
`

StyledIconListItem.Text = styled.p`
  font-size: ${typography.sizes.base};
  color: ${colors.black};
  text-align: center;
`

StyledIconListItem.IconWrapper = styled.div`
  background-color: ${colors.primary};
  color: white;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  i {
    font-size: 50px;
  }
`

export default StyledIconListItem
