import React from 'react'
import PropTypes from 'prop-types'
import StyledCardFooter from './style'
import { PreHeader } from '../PreHeader'
import { Divider } from '../Divider'

const CardFooter = ({
  children,
  header,
  footerText,
  color = 'white',
  contentAlignment,
  theme,
}) => {
  return (
    <StyledCardFooter contentAlignment={contentAlignment} theme={theme}>
      {header && (
        <StyledCardFooter.ContentWrapper>
          <PreHeader color={color}>{header}</PreHeader>
          <Divider size={'xxSmall'} />
          {footerText && (
            <StyledCardFooter.ContentWrapper.Text>
              {footerText}
            </StyledCardFooter.ContentWrapper.Text>
          )}
        </StyledCardFooter.ContentWrapper>
      )}

      {children}
    </StyledCardFooter>
  )
}

CardFooter.propTypes = {
  children: PropTypes.any,
  header: PropTypes.string,
  footerText: PropTypes.string,
  flexDirection: PropTypes.string,
}

export { CardFooter }
