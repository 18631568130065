import styled from 'styled-components'
import { mq } from '../../../styles/utilities/variables'

const StyledRevenueIcon = styled.div`
  font-size: 0;
  line-height: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  width: 12rem;
  margin: 0 auto;

  @media (max-width: 1440px) {
    justify-content: start;
    margin: unset;
  }
  @media ${mq.mobile} {
    width: 15rem;
  }

  svg {
    align-self: center;
    text-align: center;
    width: 100%;
  }
`

export default StyledRevenueIcon
