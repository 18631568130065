import styled, { css } from 'styled-components'
import { typography, mq, spacing } from '../../styles/utilities/variables'

const StyledAnimatedCounter = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	@media ${mq.tablet} {
		margin: ${spacing.large} 0 0;
	}
	@media ${mq.mobile} {
		font-size:5rem;
		margin: ${spacing.medium} 0 0;
	}
`

StyledAnimatedCounter.Number = styled.div`
	font-family: ${typography.secondaryFont};
	font-weight: bold;
	text-align: center;
	font-size: 6.4rem;
	transform: scale(0.8);
	${props => props.grow && css`
		transition: all .2s ease-in-out;
		transform: scale(1);
	`}
`

StyledAnimatedCounter.Unit = styled.span`
	font-weight:normal;
	font-size: 6.4rem;
	font-family: ${typography.secondaryFont};
	display: inline-block;
`

export default StyledAnimatedCounter