import styled, { css } from 'styled-components'
import { colors, mq, spacing } from '../../styles/utilities/variables'
import StyledImage from '../Image/style'
import StyledHeading from '../Heading/style'
import { motion } from 'framer-motion'

export const StyledCircle = styled(motion.div)`
  width: ${props => (props.width ? `${props.width}rem` : `64.8rem`)};
  background-color: ${props =>
    props.themeColor && props.invertColor
      ? colors[props.themeColor].primary
      : props.themeColor
      ? colors[props.themeColor].secondary
      : '#c9c9c9'};
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: flex;
  position: ${props => (props.noContainer ? '' : 'absolute')};
  top: 0%;

  ${props =>
    props.align === 'left' &&
    css`
      right: 84%;
      @media ${mq.mobile} {
        right: unset;
        left: 0;
        width: 32rem;
        transform: translateX(-100px);
      }
    `}
  ${props =>
    props.align === 'right' &&
    css`
      left: 84%;
      @media ${mq.mobile} {
        left: unset;
        right: 0;
        width: 32rem;
        transform: translateX(100px);
      }
    `}

    ${StyledImage} {
      border-radius: 50%;
     img {
        min-height: 100%;
        object-fit: cover;
        aspect-ratio: 1/1;
      }
    }
`

export const StyledCircleContainer = styled.div`
  width: 100%;
  max-width: 880px;
  margin: ${props => (props.moveUp ? '-70px auto 93px auto' : '0 auto')};
  height: 32rem;
  position: relative;
  display: flex;
  justify-content: center;
  @media ${mq.tablet} {
    margin: ${props => (props.moveUp ? '-30px auto 93px auto' : '0 auto')};
  }
  @media ${mq.mobile} {
    margin-top: ${spacing.xLarge};
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
  ${StyledHeading.h3} {
    align-self: flex-end;
  }

  ${props =>
    props.moveUp &&
    css`
      @media ${mq.mobile} {
        ${StyledCircle}, ${StyledSmallCircle} {
          position: unset;
          grid-row: 1;
        }
        ${StyledCircle} {
          grid-column: 1/10;
        }
        ${StyledSmallCircle} {
          grid-column: 2/7;
          align-self: flex-end;
        }
        ${StyledHeading.h3} {
          grid-row: 2;
          grid-column: 2/12;
          justify-self: center;
          margin-top: ${spacing.xLarge};
        }
      }
    `}
`

export const StyledSmallCircle = styled(motion.div)`
  width: 32.2rem;
  aspect-ratio: 1/1;
  z-index: 1;
  border-radius: 50%;
  margin-bottom: 0.2rem;
  background-color: ${props =>
    props.themeColor && props.invertColor
      ? colors[props.themeColor].secondary
      : props.themeColor
      ? colors[props.themeColor].primary
      : '#B1B1B1'};

  ${props =>
    props.hasImage &&
    css`
      background-color: transparent;
    `}
  position: ${props => (props.noContainer ? '' : 'absolute')};
  top: 100%;
  ${props =>
    props.align === 'left' &&
    css`
      left: -6.4rem;
      @media ${mq.mobile} {
        left: 0;
        right: unset;
        top: unset;
        bottom: 0;
        width: 20rem;
        transform: translateX(100px);
      }
    `}
  ${props =>
    props.align === 'right' &&
    css`
      right: -6.4rem;
      @media ${mq.mobile} {
        left: unset;
        right: 0;
        top: unset;
        bottom: 0;
        width: 20rem;
        transform: translateX(-100px);
      }
    `}


    ${StyledImage} {
      border-radius: 50%;
      height: 100%;
      align-items: center;
     img {
        min-height: 100%;
        object-fit: cover;
        aspect-ratio: 1/1;
      }
    }
`
