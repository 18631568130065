import React from 'react'
import PropTypes from 'prop-types'
import StyledSection from './style'

const Section = props => {
  const { children, dark, light, backgroundImage, indented } = props
  // prettier-ignore
  const theme = 
    (dark && 'dark') || 
    (light && 'light') || 
    'light' // default

  const backgroundImageUrl = backgroundImage ? backgroundImage.sourceUrl : null
  const innerBlocks = backgroundImageUrl || indented ? <div>{children}</div> : children
  return theme === 'light' ? (
    <StyledSection.Light indented={indented} backgroundImage={backgroundImageUrl}>
      {innerBlocks}
    </StyledSection.Light>
  ) : (
    <StyledSection.Dark indented={indented} backgroundImage={backgroundImageUrl}>
      {innerBlocks}
    </StyledSection.Dark>
  )
}

Section.propTypes = {
  children: PropTypes.any,
  dark: PropTypes.bool,
  light: PropTypes.bool,
  indented: PropTypes.bool,
  backgroundImage: PropTypes.string,
}

export { Section }
